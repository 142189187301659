import { Button, Modal } from "flowbite-react";
import Icon from "~/components/common/Icon";
import ExclamationCircleIcon from "@/far/exclamation-circle.svg";
import React from "react";

export default function DeleteConfirmationDialog({
  openModal,
  setOpenModal,
  onConfirm,
  children,

  text = undefined,
  confirmationLabel = undefined,
  cancelLabel = undefined,
}) {
  const handleOnConfirm = React.useCallback(() => {
    onConfirm();
    setOpenModal(undefined);
  }, [onConfirm, setOpenModal]);

  return (
    <Modal
      show={openModal === "pop-up"}
      size="md"
      popup
      dismissible
      onClose={() => setOpenModal(undefined)}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="space-y-4">
          <div className="text-center">
            <Icon
              Svg={ExclamationCircleIcon}
              className="mx-auto fill-gray-400 dark:fill-gray-300"
              size={42}
            />
          </div>
          <h3 className="text-lg font-normal text-gray-500 dark:text-gray-300">
            {text || "Are you sure you want to delete this?"}
          </h3>
          <div>{children}</div>
          <div className="flex justify-end gap-4 pt-4">
            <Button color="gray" onClick={() => setOpenModal(undefined)}>
              {cancelLabel || "No, cancel"}
            </Button>
            <Button color="failure" onClick={handleOnConfirm}>
              {confirmationLabel || "Yes, delete"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import { Alert, Button } from "flowbite-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Icon from "~/components/common/Icon";
import Google from "@/fab/google.svg";
import useAuthQuery from "~/hooks/useAuthQuery";
import React from "react";
import TightCard from "~/components/common/TightCard";

import Logo from "~/assets/img/logo.svg";
import ShinyBlob from "~/components/common/ShinyBlob";
import Page from "~/components/Page";
import { Auth } from "~/services/firebase";
import Subtitle from "~/components/common/Subtitle";
import ErrorText from "~/components/common/ErrorText";

const HOW_TO_ENABLE_POPUPS_URL =
  "https://www.fontbonne.edu/wp-content/uploads/2020/12/Disable-Pop-Up-Blocker.pdf";

function TosAndPrivacy() {
  return (
    <Subtitle className="mt-12 !text-gray-500 dark:!text-gray-400">
      By signing in, you agree to our{" "}
      <a
        className="underline"
        href="https://getfrankie.app/terms"
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms of Service
      </a>{" "}
      and{" "}
      <a
        className="underline"
        href="https://getfrankie.app/privacy"
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy Policy
      </a>
    </Subtitle>
  );
}

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loggingIn, setLoggingIn] = React.useState(false);

  const { isCredentialLoaded, authError, invalidate, isLoading } = useAuthQuery(
    loggingIn ? { method: "popup" } : { method: "redirect" },
  );

  const [searchParams] = useSearchParams();

  // @ts-ignore
  const isPopupBlocked = authError?.code === "auth/popup-blocked";

  // @ts-ignore
  const isUserClosed = authError?.code === "auth/popup-closed-by-user";

  const from =
    location.state?.from?.pathname || searchParams.get("from") || "/";

  async function handleLoginWithGoogle() {
    await invalidate();
    setLoggingIn(true);
  }

  React.useEffect(() => {
    if (isCredentialLoaded) {
      navigate(from);
    }
  }, [isCredentialLoaded, from, navigate]);

  React.useEffect(() => {
    Auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/");
      }
    });
  }, [navigate]);

  let error = null;

  if (isPopupBlocked) {
    error = (
      <div className="mt-3">
        <Alert color="red">
          Popups are blocked. Please{" "}
          <a
            className="underline"
            href={HOW_TO_ENABLE_POPUPS_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            enable popups
          </a>{" "}
          and try again.
        </Alert>
      </div>
    );
  } else if (authError && !isUserClosed) {
    error = (
      <ErrorText
        className="mt-4 text-red-500"
        fallback="Error trying to sign in"
      >
        Error - {authError.toString()}
      </ErrorText>
    );
  }

  return (
    <Page
      className="relative z-0 flex h-screen w-screen flex-col items-center justify-center overflow-hidden p-4"
      hideNavigation
    >
      <ShinyBlob className="hidden dark:block" />
      <TightCard className="w-full p-4 text-center shadow-lg dark:!bg-gray-825 dark:shadow-2xl md:max-w-md md:p-8">
        <div className="mb-12 space-y-2">
          <Icon Svg={Logo} size={100} />
          <h1 className="mb-3 text-2xl font-bold">Frankie AI</h1>
        </div>

        <Button
          onClick={handleLoginWithGoogle}
          gradientDuoTone="pinkToOrange"
          size="lg"
          className="group flex w-full flex-row items-center justify-center"
          fullSized
          processingLabel="Signing in..."
          isProcessing={isLoading}
        >
          <Icon
            Svg={Google}
            className="mr-4 mt-0.5 fill-gray-300 dark:fill-gray-300"
            size={13}
          />
          Sign in with Google
        </Button>

        {error}

        <TosAndPrivacy />
      </TightCard>
    </Page>
  );
}

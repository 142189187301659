import clsx from "clsx";
import { capitalize } from "lodash";
import { NOTION_SELECT_COLORS } from "~/consts/notionColors";

export default function NotionBadge({ label, className }) {
  return (
    <span
      className={clsx(
        "mr-2 rounded border px-2.5 py-0.5 text-xs font-medium",
        NOTION_SELECT_COLORS[label.color],
        className,
      )}
    >
      {capitalize(label.name)}
    </span>
  );
}

import React from "react";
import useNotion from "~/hooks/useNotion";
import { useAntiStrictMutation } from "~/hooks/useAntiStrictMutation";
import { useProjectContext } from "~/containers/ProjectContainer";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import * as notionService from "~/services/notion";
import * as rtdb from "firebase/database";
import * as firebaseService from "~/services/firebase";
import { useRollbar } from "@rollbar/react";

const DEFAULT_LABELS = [
  {
    name: "Personal",
    color: "blue",
  },
  {
    name: "Work",
    color: "red",
  },
  {
    name: "School",
    color: "orange",
  },
  {
    name: "Hobby",
    color: "purple",
  },
  {
    name: "Financial",
    color: "green",
  },
  {
    name: "Other",
    color: "gray",
  },
];

const CreateTaskListPageContext = React.createContext({
  /**
   * @type {import("@tanstack/react-query").UseQueryResult<import("@notionhq/client/build/src/api-endpoints").PageObjectResponse, unknown>}
   */
  pageQuery: null,

  /**
   * @type {import("@tanstack/react-query").UseQueryResult<import("@notionhq/client/build/src/api-endpoints").DatabaseObjectResponse, unknown>}
   */
  databaseQuery: null,

  /**
   * @type {import("@tanstack/react-query").UseQueryResult<import("@notionhq/client/build/src/api-endpoints").ListBlockChildrenResponse, unknown>}
   */
  pageBlocksQuery: null,

  /** @type {import("@tanstack/react-query").UseMutationResult<any, any>} */
  createTaskListMutation: null,

  newDatabaseSchema: {},
  setNewDatabaseSchema: (_) => {},

  selectedPageId: null,
  setSelectedPageId: (_) => {},

  canCreate: false,
  isCreating: false,
  isCreatingFromAutoCreate: false,

  /**
   * @type {string | null}
   */
  loadingStep: null,

  /**
   * @type {string | null}
   */
  verificationError: null,
});

export function useCreateTaskListPageContext() {
  const context = React.useContext(CreateTaskListPageContext);

  if (context === undefined) {
    throw new Error(
      "useCreateTaskListPageContext must be used within an ConnectPageProvider",
    );
  }

  return context;
}

export const CreateTaskListPageProvider = ({
  children,
  defaultSelectedPageId = null,
  defaultTitle,
  autoCreate,
}) => {
  const rollbar = useRollbar();
  const { createTaskList } = useProjectContext();
  const navigator = useNavigate();

  const [isCreatingFromAutoCreate, setIsCreatingFromAutoCreate] =
    React.useState(false);

  const [loadingStep, setLoadingStep] = React.useState(null);

  const [selectedPageId, setSelectedPageId] = React.useState(
    defaultSelectedPageId,
  );

  const [newDatabaseSchema, setNewDatabaseSchema] = React.useState({
    name: defaultTitle,
    labels: DEFAULT_LABELS,
  });

  const [newTaskList, setCreatedTaskList] = React.useState(null);

  const [verificationError, setVerificationError] = React.useState(null);

  const pageBlocksQuery = useQuery(
    ["notionBlocks", selectedPageId],
    () => notionService.fetchBlocks(selectedPageId),
    {
      enabled: Boolean(selectedPageId),
      suspense: false,
      useErrorBoundary: false,
    },
  );

  const { pageQuery, databaseQuery } = useNotion(selectedPageId);

  /** @type {import("@tanstack/react-query").UseMutationResult<any, any>} */
  const createTaskListMutation = useAntiStrictMutation({
    mutationFn: async () => {
      const selectedPage = pageQuery.data;
      const selectedDatabase = databaseQuery.data;

      if (!selectedPage?.id) {
        throw new Error("No page selected");
      }

      setLoadingStep("Creating database...");

      let databaseId = selectedDatabase?.id;

      if (!databaseId) {
        const { name, labels } = newDatabaseSchema;

        // Create new database //
        const newNotionDatabase = await notionService.createDatabase(
          selectedPageId,
          name,
          {
            Title: {
              title: {},
            },
            Label: {
              select: {
                options: labels,
              },
            },
            Description: {
              rich_text: {},
            },
            Duration: {
              number: {},
            },
            "Start Time": {
              date: {},
            },
          },
        );

        databaseId = newNotionDatabase.id;
      }

      setLoadingStep("Creating task list...");

      const newTaskList = await createTaskList({
        pageId: selectedPage.id,
        databaseId: databaseId,
        pageUrl: selectedPage.url,
        name: newDatabaseSchema.name || defaultTitle,
      });

      setLoadingStep("Verifying...");
      setCreatedTaskList(newTaskList);
    },
  });

  // Verify if task list was created //
  React.useEffect(() => {
    if (!newTaskList) {
      return;
    }

    let isMounted = true;

    const verify = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      for (let i = 0; i < 5; i++) {
        if (!isMounted) {
          return false;
        }

        const notionPage = await notionService.fetchPage(
          newTaskList.notionPageId,
        );

        let snapshotExists = false;
        try {
          const snapshot = await rtdb.get(
            firebaseService.TaskListRef(newTaskList.id),
          );

          snapshotExists = snapshot.exists();
        } catch (error) {
          // Ignore permission denied error. The list might be created but the permissions are still updating //
          if (error?.message !== "Permission denied") {
            throw error;
          }
        }

        if (notionPage?.url && snapshotExists) {
          return true;
        }

        await new Promise((resolve) => setTimeout(resolve, 1000 + i * 1000));
      }

      throw new Error("Unable to navigate to task list.");
    };

    verify()
      .then((result) => {
        if (isMounted && result && newTaskList) {
          // Navigate to task list with clean state //
          // TODO: Fix the bug that prevents from using navigate() here
          window.location.href = `/list/${newTaskList.id}`;
        }
      })
      .catch((error) => {
        // TODO: Handle error
        if (isMounted) {
          setVerificationError(error.toString());
          rollbar.error(error);
        }
      })
      .finally(() => {
        setLoadingStep(null);
      });

    return () => {
      isMounted = false;
    };
  }, [newTaskList, rollbar]);

  const canCreate = Boolean(
    ((newDatabaseSchema?.name && newDatabaseSchema?.labels.length) || // If is new database
      databaseQuery.data?.id) && // Or if is existing database
      pageQuery.data?.id, // And if has selected page
  );

  React.useEffect(() => {
    if (autoCreate) {
      navigator("/list/create", {
        state: {
          defaultSelectedPageId,
          defaultTitle,
        },
        replace: true,
      });

      if (
        pageQuery.data?.id &&
        databaseQuery.data?.id &&
        loadingStep === null &&
        createTaskListMutation.isIdle
      ) {
        setIsCreatingFromAutoCreate(true);
        createTaskListMutation.mutate();
      }
    }
  }, [
    autoCreate,
    pageQuery,
    databaseQuery,
    loadingStep,
    createTaskListMutation,
    navigator,
    defaultSelectedPageId,
    defaultTitle,
  ]);

  return (
    <CreateTaskListPageContext.Provider
      value={{
        pageQuery,
        databaseQuery,
        pageBlocksQuery,
        createTaskListMutation,
        newDatabaseSchema,
        setNewDatabaseSchema,
        selectedPageId,
        setSelectedPageId,
        canCreate,
        isCreating: createTaskListMutation.isLoading,
        isCreatingFromAutoCreate,
        loadingStep,
        verificationError,
      }}
    >
      {children}
    </CreateTaskListPageContext.Provider>
  );
};

import Icon from "../common/Icon";
import Logo from "~/assets/img/logo.svg";
import RightArrowIcon from "@/fas/arrow-right.svg";
import { Button } from "flowbite-react";

export default function EmbedUnauthenticated() {
  return (
    <div className="flex h-full flex-col items-center justify-center pb-16">
      <div className="space-y-10 p-4 text-center">
        <Icon Svg={Logo} size={100} />
        <div>
          <h1 className="mb-2 text-3xl font-black">FrankieAI</h1>
          <p className="text-base">Create a task list to begin</p>
        </div>
        <Button
          gradientMonochrome="purple"
          fullSized
          className="group"
          href="/list"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get Started
          <Icon
            Svg={RightArrowIcon}
            size={12}
            className="ml-2 fill-gray-300 transition-all group-hover:ml-4"
          />
        </Button>
      </div>
    </div>
  );
}

import React from "react";
import { useAuthContext } from "~/containers/AuthContainer";
import * as firebaseService from "~/services/firebase";
import * as rtdb from "firebase/database";
import useManagedCallback from "~/hooks/useManagedCallback";

// TODO: Move this to a more universal location

const SettingsPageContext = React.createContext({
  clearNotionCredentials: () => {},
  deleteAccount: () => {},
});

export function useSettingsPageContext() {
  const context = React.useContext(SettingsPageContext);

  if (context === undefined) {
    throw new Error(
      "useSettingsPageContext must be used within an SettingsPageProvider",
    );
  }

  return context;
}

export const SettingsPageProvider = ({ children }) => {
  const { loggedInUser } = useAuthContext();

  const clearNotionCredentials = useManagedCallback(async () => {
    if (!loggedInUser) return;

    await rtdb.remove(firebaseService.UsersRef("notion"));

    localStorage.removeItem("notionAccessToken");
    location.reload();
  }, [loggedInUser]);

  const deleteAccount = useManagedCallback(async () => {
    if (!loggedInUser) return;

    await firebaseService.deleteAccount();
    location.reload();
  }, [loggedInUser]);

  return (
    <SettingsPageContext.Provider
      value={{ clearNotionCredentials, deleteAccount }}
    >
      {children}
    </SettingsPageContext.Provider>
  );
};

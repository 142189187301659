import SettingSection from "~/components/settings/SettingSection";
import { useSettingsPageContext } from "~/components/settings/SettingsPageContainer";
import ButtonConfig from "~/components/settings/forms/ButtonConfig";

export default function IntegrationsPane() {
  const settingsPageContext = useSettingsPageContext();
  const handleClearNotionCredentials =
    settingsPageContext.clearNotionCredentials;

  return (
    <SettingSection title="Integrations">
      <ButtonConfig
        label="Reconnect"
        onClick={handleClearNotionCredentials}
        title="Reconnect Notion"
        description="This will trigger a reconnection."
        confirm
      />
    </SettingSection>
  );
}

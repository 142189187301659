import LoadingBlock from "~/components/common/LoadingBlock";

export default function EmbedLoading() {
  return (
    <div className="h-full space-y-4 px-3 py-4">
      <LoadingBlock height="h-32" />
      <LoadingBlock height="h-12" />

      <div className="grid h-[150px] w-full grid-cols-2 gap-2">
        <LoadingBlock />
        <LoadingBlock />
        <LoadingBlock />
        <LoadingBlock />
      </div>
    </div>
  );
}

// React component that has an input field and a button to create a new task

import { Textarea } from "flowbite-react";
import { useAuthContext } from "~/containers/AuthContainer";
import TightCard from "../common/TightCard";
import Icon from "../common/Icon";
import UserIcon from "@/fas/user.svg";
import { useEmbedContext } from "~/containers/EmbedContainer";
// import SettingsIcon from "@/fas/cog.svg";

export default function CreateTask() {
  const { googleUser } = useAuthContext();
  const { addTask, inputValue, setInputValue, loading } = useEmbedContext();

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      event.target.blur();

      addTask();
    }
  };

  return (
    <TightCard>
      <div className="flex flex-col space-y-4">
        <div className="space-x-4">
          <a
            href="/list"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block text-xs font-semibold underline decoration-purple-500 decoration-1 underline-offset-4 hover:decoration-2"
          >
            <Icon Svg={UserIcon} className="mr-2" size={12} />
            {googleUser.displayName}
          </a>
          {/* <a
            onClick={() => {}}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block text-xs font-semibold hover:cursor-pointer"
          >
            <Icon Svg={SettingsIcon} className="mr-2" size={12} />
            Settings
          </a> */}
        </div>
        <Textarea
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          onKeyDown={handleOnKeyDown}
          value={inputValue}
          placeholder="Add a task"
          disabled={loading}
          rows={2}
          className="resize-none"
          shadow
        />
      </div>
    </TightCard>
  );
}

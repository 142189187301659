import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import * as firebaseDatabase from "firebase/database";

export function useDatabasesQuery({ queryKey, databaseRefs, mapperFn = null, options = {} }) {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const entriesPromises = databaseRefs.map(async (databaseRef) => {
        const snapshot = await firebaseDatabase.get(databaseRef);

        if (!snapshot.exists()) {
          // TODO: Handle error
          return null;
        }

        let data = snapshot.val();

        if (mapperFn) {
          data = mapperFn(data, snapshot);
        }

        return [snapshot.key, data];
      });

      const entries = await Promise.all(entriesPromises);

      return Object.fromEntries(entries.filter(Boolean));
    },
    ...options,
  });

  React.useEffect(() => {
    const currentData = queryClient.getQueryData(queryKey) || {};

    const updateQuery = (snapshot) => {
      let data = snapshot.val();

      if (data === null) {
        delete currentData[snapshot.key];
      } else {
        if (mapperFn) {
          data = mapperFn(data, snapshot);
        }

        currentData[snapshot.key] = data;
      }

      // @ts-ignore
      queryClient.setQueryData(queryKey, { ...currentData });
    };

    for (const databaseRef of databaseRefs) {
      firebaseDatabase.onValue(databaseRef, updateQuery);
    }

    return () => {
      for (const databaseRef of databaseRefs) {
        firebaseDatabase.off(databaseRef, "value", updateQuery);
      }
    };
  }, [queryClient, queryKey, databaseRefs, mapperFn]);

  return query;
}

// Determines if the user is logged in or not. If not, redirects to login page.
import { Outlet } from "react-router-dom";
import { AuthProvider } from "~/containers/AuthContainer";
import { ProjectProvider } from "~/containers/ProjectContainer";

export default function AuthLayoutRoute() {
  return (
    <AuthProvider>
      <ProjectProvider>
        <Outlet />
      </ProjectProvider>
    </AuthProvider>
  );
}

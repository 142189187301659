import { Label, TextInput, Button } from "flowbite-react";
import Icon from "~/components/common/Icon";
import { useCreateTaskListPageContext } from "~/components/create-task-list/CreateTaskListPageContainer";
import LabelRow from "~/components/create-task-list/LabelRow";
import HeadingIcon from "@/far/heading.svg";

export default function DatabaseEditor() {
  const { databaseQuery, newDatabaseSchema, setNewDatabaseSchema } =
    useCreateTaskListPageContext();

  const selectedDatabaseId = databaseQuery.data?.id;

  return (
    <>
      <div className="mb-4">
        <div className="mb-2 block">
          <Label htmlFor="taskListTitle" value="Title" />
        </div>
        <TextInput
          id="taskListTitle"
          addon={<Icon Svg={HeadingIcon} size={16} />}
          placeholder="Frankie's Task List"
          required
          type="text"
          value={newDatabaseSchema?.name || ""}
          onChange={(e) =>
            setNewDatabaseSchema({ ...newDatabaseSchema, name: e.target.value })
          }
        />
      </div>

      <div className="mb-4">
        <div className="mb-2 block">
          <Label value="Labels" />
        </div>

        <div className="mb-4 w-full">
          {newDatabaseSchema.labels.map((label, index) => (
            <LabelRow
              key={label.name}
              label={label}
              readonly={Boolean(selectedDatabaseId)}
              hasConflict={(proposedLabelName) =>
                Boolean(
                  newDatabaseSchema.labels.find(
                    (l) =>
                      l.name.toLocaleLowerCase() ===
                      proposedLabelName.toLocaleLowerCase(),
                  ),
                )
              }
              onSave={(updatedLabel) =>
                setNewDatabaseSchema((old) => {
                  const newLabels = [...old.labels];
                  newLabels[index] = updatedLabel;

                  return { ...old, labels: newLabels };
                })
              }
              onDelete={() =>
                setNewDatabaseSchema((old) => ({
                  ...old,
                  labels: old.labels.filter((_, i) => i !== index),
                }))
              }
            />
          ))}
        </div>

        {!selectedDatabaseId && (
          <Button
            pill
            color="gray"
            size="xs"
            disabled={newDatabaseSchema.labels.find(
              (l) => l.name === "New Label",
            )}
            className="w-full"
            onClick={() =>
              setNewDatabaseSchema((old) => ({
                ...old,
                labels: [
                  ...old.labels,
                  { name: "New Label", color: "default" },
                ],
              }))
            }
          >
            Add Label
          </Button>
        )}
      </div>
    </>
  );
}

// Container for the task list

import React from "react";
import * as notionService from "~/services/notion";
import * as aiService from "~/services/ai";
import { useProjectContext } from "./ProjectContainer";
import { useQuery } from "@tanstack/react-query";
import { swapTimezones } from "~/utils/time";
import { NotFoundError } from "~/utils/NotFoundError";

const TaskContext = React.createContext({
  taskList: null,
  previewTask: (_query) => null,
  createTask: (_task) => {},
  createTaskWithAI: (_query) => {},
  restoreEmbeds: (_) => {},

  /**
   * A raw useQuery representation of the Notion page for the task list.
   * @deprecated This should be replaced with a useNotion hook
   */
  notionPageQuery: null,
});

export function useTaskContext() {
  const context = React.useContext(TaskContext);

  if (!context) {
    throw new Error("useTaskContainer must be used within an TaskProvider");
  }

  return context;
}

export function TaskProvider({ taskListId, children }) {
  const { taskLists, isTaskListLoading } = useProjectContext();
  const taskList = isTaskListLoading ? null : taskLists[taskListId];

  const previewTask = React.useCallback(
    async (query) => {
      if (!taskList?.id) {
        // TODO: Prompt the user to reconnect their embed.
        throw new Error("No task list found");
      }

      let preview = await aiService.enrichTaskQuery(taskList.id, query);

      preview = {
        ...preview,
        startTime: swapTimezones(
          preview.startTime,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        ).toISO({ includeOffset: false }),
      };

      // TODO: Validate the preview is correct
      return preview;
    },
    [taskList?.id],
  );

  const createTask = React.useCallback(
    async (task) => {
      const { notionDatabaseId } = taskList;
      const entry = notionService.createDatabaseEntry(notionDatabaseId, task);

      // TODO: Verify that the task was created successfully
      return entry;
    },
    [taskList],
  );

  const createTaskWithAI = React.useCallback(
    async ({ query }) => {
      const preview = await previewTask(query);
      const entry = await createTask(preview);

      return entry;
    },
    [previewTask, createTask],
  );

  const restoreEmbeds = React.useCallback(async () => {
    if (!taskList?.id) {
      // TODO: Prompt the user to reconnect their embed.
      throw new Error("No task list found");
    }

    await notionService.restoreEmbeds(taskList.id, taskList.notionPageId);
  }, [taskList.id, taskList.notionPageId]);

  // TODO: Use the useNotion hook instead
  const notionPageQuery = useQuery({
    queryKey: ["taskList", taskList?.notionPageId, "notionPage"],
    queryFn: async () => {
      const { notionPageId } = taskList;
      const notionPage = await notionService.fetchPage(notionPageId);
      return notionPage;
    },
    enabled: taskList?.notionPageId && !isTaskListLoading,
    suspense: false,
    useErrorBoundary: false,
    retry: false,
  });

  if (isTaskListLoading) {
    return null;
  }

  if (!taskList) {
    throw new NotFoundError(`No task list found for id: ${taskListId}`);
  }

  return (
    <TaskContext.Provider
      value={{
        taskList,
        previewTask,
        createTask,
        createTaskWithAI,
        restoreEmbeds,

        notionPageQuery,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
}

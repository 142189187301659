import { Transition } from "@headlessui/react";
import React from "react";
import Icon from "~/components/common/Icon";
import { useAppContext } from "~/containers/AppContainer";
import BarsIcon from "@/fal/bars.svg";

export default function MobileNav() {
  const { isMobile, isSidebarOpen, setIsSidebarOpen } = useAppContext();

  return (
    <>
      <Transition
        as={React.Fragment}
        show={isSidebarOpen && isMobile}
        enter="ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 z-10 bg-black bg-opacity-75 transition-opacity" />
      </Transition>

      <div className="fixed top-0 z-10 w-full sm:hidden">
        <div className="flex h-16 justify-between bg-gray-50 px-5 py-4 text-base dark:bg-gray-800 dark:text-gray-300">
          <a href="/list" className="flex items-center">
            <img
              className="mr-3 h-6 sm:h-7"
              src="/logo.svg"
              alt="FrankieAI logo"
            />
            <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-gray-300">
              FrankieAI
            </span>
          </a>
          <button
            className="flex items-center justify-center"
            onClick={() => setIsSidebarOpen(true)}
          >
            <Icon Svg={BarsIcon} size={20} />
          </button>
        </div>
      </div>
    </>
  );
}

import DarkModeToggle from "~/components/DarkModeToggle";
import Version from "~/components/common/Version";

export default function LayoutFooter() {
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <Version />
      <DarkModeToggle />
    </div>
  );
}

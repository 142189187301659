import { Dropdown, TextInput } from "flowbite-react";

import FarClock from "@/far/clock.svg";
import TightCard from "../common/TightCard";
import Icon from "../common/Icon";
import TitleIcon from "@/fas/text.svg";
import AlignLeftIcon from "@/fas/align-left.svg";
import { useEmbedContext } from "~/containers/EmbedContainer";
import { useTaskContext } from "~/containers/TaskContainer";
import useNotion from "~/hooks/useNotion";
import NotionBadge from "~/components/common/integration/notion/NotionBadge";
import React from "react";
import LoadingBlock from "~/components/common/LoadingBlock";
import CloseButton from "~/components/common/CloseButton";

const previewInputTheme = {
  field: {
    input: {
      sizes: {
        sm: "p-1.5 text-xs",
      },
    },
  },
};

export default function TaskPreview() {
  const {
    isEditing,
    preview: _preview = {},
    setPreview: _setPreview,
  } = useEmbedContext();
  const { taskList } = useTaskContext();
  const { databaseQuery } = useNotion(taskList?.notionPageId);

  const labels = React.useMemo(
    // @ts-ignore
    () => databaseQuery?.data?.properties?.Label?.select?.options || [],
    [databaseQuery],
  );

  const selectedLabel = labels.find(
    (l) =>
      _preview?.label &&
      l.name.toLocaleLowerCase() === _preview.label.toLocaleLowerCase(),
  );

  const preview = React.useMemo(
    () => ({
      ..._preview,
      label: selectedLabel || { name: _preview.label || "", color: "default" },
    }),
    [_preview, selectedLabel],
  );

  const setPreview = React.useCallback(
    (attrs) => {
      if (!attrs) {
        _setPreview(null);
        return;
      }

      _setPreview({
        ..._preview,
        ...attrs,
      });
    },
    [_setPreview, _preview],
  );

  if (!preview || databaseQuery.isLoading) {
    return <LoadingBlock height="h-36" />;
  }

  const readonlyPreview = (
    <div>
      <h3 className="mb-2 text-xl font-bold tracking-tight">{preview.title}</h3>

      <div className="mb-4 flex items-center space-x-5">
        <div>
          <NotionBadge className="inline" label={preview.label} />
        </div>

        <span className="flex items-center text-sm text-gray-500 dark:text-gray-400">
          <FarClock
            // @ts-ignore
            className="mr-1 inline-block fill-gray-500 dark:fill-gray-400"
            width={14}
          />
          {preview.duration} mins
        </span>
      </div>

      <p className="text-base">{preview.description}</p>
    </div>
  );

  const editablePreview = (
    <div>
      <TextInput
        addon={<Icon Svg={TitleIcon} size={14} />}
        theme={previewInputTheme}
        className="mb-2"
        value={preview.title}
        sizing="sm"
        placeholder="Task title"
        required
        shadow
        onChange={(event) => {
          setPreview({
            title: event.target.value,
          });
        }}
      />

      <div className="mb-2 flex items-center space-x-2">
        <Dropdown
          label={
            <NotionBadge
              className="mr-12 inline-block py-0.5"
              label={preview.label}
            />
          }
          color="gray"
          className="min-w-[260px]"
          trigger="click"
          placement="right"
          size="xs"
        >
          {labels.map((label) => (
            <Dropdown.Item
              key={label.id}
              onClick={() =>
                setPreview({
                  label: label.name,
                })
              }
            >
              <NotionBadge className="inline" label={label} />
            </Dropdown.Item>
          ))}
        </Dropdown>

        <TextInput
          theme={previewInputTheme}
          value={preview.duration}
          sizing="sm"
          type="number"
          shadow
          placeholder="Duration (in minutes)"
          addon={<Icon Svg={FarClock} size={14} />}
          onChange={(event) => {
            setPreview({
              duration: +event.target.value,
            });
          }}
        />
      </div>

      <TextInput
        theme={previewInputTheme}
        sizing="sm"
        shadow
        addon={<Icon Svg={AlignLeftIcon} size={14} />}
        value={preview.description}
        onChange={(event) => {
          setPreview({
            description: event.target.value,
          });
        }}
      />
    </div>
  );

  return (
    <TightCard className="relative h-[150px]">
      <CloseButton
        onClose={() => setPreview(null)}
        className="absolute right-4 top-2"
      />
      {isEditing ? editablePreview : readonlyPreview}
    </TightCard>
  );
}

import clsx from "clsx";

const colors = {
  green: "bg-lime-500 shadow-lime-500/30",
  red: "bg-red-500 shadow-red-500/20",
  yellow: "bg-yellow-500 shadow-yellow-500/20",
  blue: "bg-sky-400 shadow-sky-400/20",
  primary: "bg-purple-500 shadow-purple-500/20",
};

export default function PulseStatusIndicator({
  color,
  loading = false,
  className = "",
  ...props
}) {
  return (
    <div
      className={clsx(
        "inline-block h-2 w-2 rounded-full",
        loading ? "animate-pulse" : "shadow-[0_0_0_4px]",
        colors[color],
        className,
      )}
      {...props}
    />
  );
}

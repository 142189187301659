import React from "react";
import { useWindowSize } from "~/hooks/useWindowSize";

// See https://tailwindcss.com/docs/screens
const MOBILE_WIDTH = 640;

const AppContext = React.createContext({
  isSidebarContentVisible: true,
  setIsSidebarContentVisible: (_) => {},
  isSidebarOpen: false,
  setIsSidebarOpen: (_) => {},
  isMobile: window.innerWidth < MOBILE_WIDTH,
  setDarkModeScope: (_) => {},
  darkModeScope: "default",
});

export function useSidebarOpen(isOpen) {
  const { setIsSidebarOpen } = useAppContext();

  React.useEffect(() => {
    setIsSidebarOpen(isOpen);
  }, [isOpen, setIsSidebarOpen]);
}

export function useSidebarContentVisible(isVisible) {
  const { setIsSidebarContentVisible } = useAppContext();

  React.useEffect(() => {
    setIsSidebarContentVisible(isVisible);
  }, [isVisible, setIsSidebarContentVisible]);
}

export function useDarkModeScope(scope) {
  const { setDarkModeScope } = useAppContext();

  React.useEffect(() => {
    setDarkModeScope(scope);
  }, [scope, setDarkModeScope]);
}

export function useAppContext() {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }

  return context;
}

export const AppProvider = ({ children }) => {
  const [darkModeScope, setDarkModeScope] = React.useState("default");
  const [isSidebarContentVisible, setIsSidebarContentVisible] =
    React.useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const { width } = useWindowSize();

  return (
    <AppContext.Provider
      value={{
        isSidebarContentVisible,
        setIsSidebarContentVisible,
        isSidebarOpen,
        setIsSidebarOpen,
        isMobile: width < MOBILE_WIDTH,
        setDarkModeScope,
        darkModeScope,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";

import App from "~/App.jsx";
import "./index.css";

export const SCOPES = {
  DEFAULT: "default",
  EMBED: "embed",
};

export const DEFAULT_SCOPE_VALUES = {
  [SCOPES.DEFAULT]: "true",
  [SCOPES.EMBED]: "false",
};

/**
 * Apply default values to local storage for dark mode.
 *
 * Should only be called once on app load.
 *
 * TODO: Refactor this. This is super hacky.
 */
export function applyDefaultThemeValues() {
  for (const scopeKey in SCOPES) {
    const scope = SCOPES[scopeKey];
    if (localStorage.getItem(`usehooks-ts-dark-mode-${scope}`)) {
      continue;
    }

    const value = DEFAULT_SCOPE_VALUES[scope];
    localStorage.setItem(`usehooks-ts-dark-mode-${scope}`, value);
  }
}

applyDefaultThemeValues();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

import MobileNav from "~/components/layout/MobileNav";
import LayoutFooter from "./layout/LayoutFooter";
import LayoutSidebar from "./layout/LayoutSidebar";
import clsx from "clsx";
import Container from "~/components/common/Container";

export default function Page({
  children,
  className = "",
  hideNavigation = false,
  hideFooter = false,
  ...props
}) {
  return (
    <div
      className="relative flex flex-1 flex-row items-stretch bg-white text-gray-900 bg-dots-gray-500/20 dark:bg-gray-875 dark:text-gray-300 dark:bg-grid-gray-600/10"
      {...props}
    >
      {!hideNavigation && (
        <>
          <MobileNav />
          <LayoutSidebar />
        </>
      )}

      {/*
       * pt-16 sm:pt-0 is to offset the height of the fixed top navigation
       */}
      <div
        className={clsx(
          "flex min-h-screen w-full flex-auto flex-col",
          hideNavigation ? "pt-0" : "pt-16 sm:pt-0",
        )}
      >
        <div className={clsx("w-full flex-1", className)}>{children}</div>

        {!hideFooter && (
          <Container className="py-6">
            <LayoutFooter />
          </Container>
        )}
      </div>
    </div>
  );
}

import { Button, Checkbox, Label, Tooltip } from "flowbite-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "~/components/common/Icon";
import { useProjectContext } from "~/containers/ProjectContainer";
import ArrowLeft from "@/fas/arrow-left.svg";
import SpinnerThirdIcon from "@/far/spinner-third.svg";
import BoltIcon from "@/fas/bolt.svg";
import { useTaskContext } from "~/containers/TaskContainer";
import DeleteConfirmationDialog from "~/components/common/DeleteConfirmationDialog";
import useNotion from "~/hooks/useNotion";
import LinkButton from "~/components/common/LinkButton";
import NotionIcon from "~/components/common/integration/notion/NotionIcon";
import clsx from "clsx";
import PulseStatusIndicator from "~/components/common/PulseStatusIndicator";
import Container from "~/components/common/Container";
import useManagedCallback from "~/hooks/useManagedCallback";

function Actions() {
  // TODO: Remove use of notionPageQuery
  const { taskList: selectedTaskList, notionPageQuery } = useTaskContext();
  const { deleteTaskList } = useProjectContext();

  const [openModal, setOpenModal] = React.useState(undefined);
  const [deletePage, setDeletePage] = React.useState(false);
  const parentType = notionPageQuery.data?.result?.result?.parent?.type;
  const canDeletePage = parentType && parentType !== "workspace";

  const navigate = useNavigate();

  const handleOnDelete = useManagedCallback(async () => {
    await deleteTaskList({
      taskListId: selectedTaskList.id,
      archiveNotionPage: deletePage,
    });

    // TODO: Give success message
    navigate("/list");
  }, [navigate, selectedTaskList, deleteTaskList, deletePage]);

  return (
    <>
      <DeleteConfirmationDialog
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={handleOnDelete}
      >
        <div
          className={clsx(
            "flex gap-2",
            !canDeletePage && "!cursor-not-allowed",
          )}
        >
          <div className="flex h-5 items-center">
            <Checkbox
              id="deletePage"
              disabled={!canDeletePage}
              onChange={(e) => setDeletePage(e.target.checked)}
            />
          </div>
          <div className="flex flex-col">
            <Label htmlFor="deletePage" disabled={!canDeletePage}>
              Archive{" "}
              {notionPageQuery.data?.result?.result?.url ? (
                <a
                  className="text-purple-500 hover:underline dark:text-purple-500"
                  href={notionPageQuery.data.result.result.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <p>Notion</p>
                </a>
              ) : (
                <>Notion</>
              )}{" "}
              page
            </Label>
            {!canDeletePage && (
              <div className="text-gray-500 dark:text-gray-400">
                <span className="text-xs font-normal italic">
                  <p>Cannot archive a workspace page.</p>
                </span>
              </div>
            )}
          </div>
        </div>
      </DeleteConfirmationDialog>

      <div className="flex flex-row items-center space-x-8">
        <LinkButton
          size="sm"
          color="neutral"
          onClick={() => setOpenModal("pop-up")}
        >
          Archive
        </LinkButton>

        <Tooltip content="Coming soon!">
          <Button size="sm" color="purple" disabled>
            Connect Provider
            <Icon Svg={BoltIcon} className="ml-2 fill-gray-300" size={12} />
          </Button>
        </Tooltip>
      </div>
    </>
  );
}

function BackToList() {
  return (
    <div className="bg-white py-3 dark:bg-gray-900">
      <Container className="flex items-center justify-between">
        <LinkButton href="/list" color="gray" underline={false}>
          <Icon
            Svg={ArrowLeft}
            className="mr-2 fill-gray-500 dark:fill-gray-400"
            size={12}
          />
          Back to lists
        </LinkButton>
      </Container>
    </div>
  );
}

function Info() {
  const { taskList } = useTaskContext();
  const { pageQuery } = useNotion(taskList?.notionPageId);

  return (
    <div className="flex flex-row items-center space-x-8">
      <div className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-300 p-10 dark:border-gray-600 sm:p-12">
        {pageQuery.isLoading ? (
          <Icon
            Svg={SpinnerThirdIcon}
            className="h-6 w-6 animate-spin fill-purple-500 dark:fill-purple-500"
          />
        ) : (
          <NotionIcon
            icon={pageQuery.data?.icon}
            spanProps={{ className: "p-4 text-4xl sm:text-5xl" }}
            imageProps={{
              className: "h-10 w-10 sm:h-12 sm:w-12 max-w-none",
            }}
          />
        )}
      </div>
      <div className="flex flex-col">
        <h2 className="flex flex-row items-center space-x-3 text-gray-500 dark:text-gray-400">
          <PulseStatusIndicator color="green" /> <span>AI Enabled</span>
        </h2>
        <h1 className="text-2xl font-bold">{taskList?.name || "Task List"}</h1>
      </div>
    </div>
  );
}

export default function Banner() {
  return (
    <>
      <BackToList />
      <div className="border-y border-gray-300 bg-white py-8 dark:border-gray-600/40 dark:bg-gray-850 lg:flex-row">
        <Container className="flex flex-row items-center justify-between">
          <Info />
          <Actions />
        </Container>
      </div>
    </>
  );
}

import { Button, Card } from "flowbite-react";
import Page from "~/components/Page";
import Icon from "~/components/common/Icon";
import ShinyBlob from "~/components/common/ShinyBlob";
import ErrorWarningIcon from "~/assets/img/error-warning.svg";
import Subtitle from "~/components/common/Subtitle";
import { useRouteError } from "react-router-dom";

function NotFound() {
  return (
    <div className="flex flex-col space-y-12 text-left">
      <div className="space-y-2">
        <h1 className="mb-2 text-left text-2xl">Page does not exist</h1>
        <Subtitle>The page you are looking for does not exist.</Subtitle>
      </div>

      <div className="flex flex-row space-x-4">
        <Button color="light" href="/list">
          Return Home
        </Button>

        <Button color="purple" onClick={() => window.history.back()}>
          Go back
        </Button>
      </div>
    </div>
  );
}

function UncaughtError({ error }) {
  return (
    <div className="flex flex-col space-y-12 text-left">
      <div className="space-y-2">
        <h1 className="mb-2 text-left text-2xl">Oops, something went wrong.</h1>
        <Subtitle>
          {import.meta.env.PROD ? (
            <>An error report has been submitted</>
          ) : (
            <code>Error: {error.message || error.toString()}</code>
          )}
        </Subtitle>
      </div>

      <div className="flex flex-row space-x-4">
        <Button color="light" onClick={() => window.history.back()}>
          Go back
        </Button>

        <Button color="purple" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </div>
    </div>
  );
}

export default function ErrorPage({ error = undefined }) {
  const routeError = useRouteError();

  if (error === undefined) {
    error = routeError || new Error("Unknown error");
  }

  const Component =
    error.name === "NotFound" || error.statusText === "Not Found"
      ? NotFound
      : UncaughtError;

  return (
    <Page
      className="relative z-0 flex h-screen w-screen flex-col items-center justify-center overflow-hidden p-4"
      hideNavigation
    >
      <ShinyBlob className="block" />
      <div className="fixed left-0 top-0 flex flex-row justify-start p-5">
        <a
          href="/"
          className="flex flex-row items-center justify-center space-x-2 text-xl font-black"
        >
          <img src="/logo.svg" alt="FrankieAI logo" className="h-8 w-8" />
          <span>FrankieAI</span>
        </a>
      </div>

      <Card className="w-full max-w-3xl text-center">
        <div className="flex flex-col items-center justify-center space-y-8 p-24 sm:flex-row sm:space-x-24 sm:space-y-0">
          <Icon Svg={ErrorWarningIcon} size={200} className="fill-yellow-300" />
          <Component error={error} />
        </div>
      </Card>
    </Page>
  );
}

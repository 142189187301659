import clsx from "clsx";
import React from "react";
import Icon from "~/components/common/Icon";
import ExternalLinkIcon from "@/fal/external-link.svg";

const colors = {
  primary:
    "text-purple-500 dark:text-purple-500 fill-purple-500 dark:fill-purple-500",
  gray: "text-gray-500 dark:text-gray-400 fill-gray-500 dark:fill-gray-400",
  neutral: "text-black dark:text-gray-300 fill-black dark:fill-gray-300",
  yellow:
    "text-yellow-400 dark:text-yellow-500 fill-yellow-400 dark:fill-yellow-500",
};

function LinkButton(
  {
    children,
    color = "primary",
    className = "",
    underline = true,
    external = false,
    newTab = false,
    ...props
  },
  ref,
) {
  return (
    <a
      ref={ref}
      className={clsx(
        "flex cursor-pointer items-baseline space-x-1 hover:underline",
        underline && "underline",
        colors[color],
        className,
      )}
      target={newTab ? "_blank" : undefined}
      rel="noopener noreferrer"
      {...props}
    >
      <span>{children}</span>
      {external && (
        <Icon Svg={ExternalLinkIcon} size={10} className={colors[color]} />
      )}
    </a>
  );
}

export default React.forwardRef(LinkButton);

const DEFAULT_IMAGE_PROPS = {
  className: "inline-block w-6 h-6",
};

export default function NotionIcon({
  icon,
  spanProps = {},
  imageProps = DEFAULT_IMAGE_PROPS,
}) {
  if (!icon) return null;

  if (icon.type === "emoji") {
    return <span {...spanProps}>{icon.emoji}</span>;
  }

  if (icon.type === "external" || icon.type === "file") {
    return (
      <img
        src={icon.external?.url || icon.file?.url}
        alt="Notion page icon"
        {...imageProps}
      />
    );
  }

  return null;
}

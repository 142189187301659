import Icon from "./Icon";
import BlobImage from "~/assets/img/blob.svg";
import clsx from "clsx";

export default function ShinyBlob({ className = "", ...props }) {
  return (
    <div
      className={clsx(
        "absolute -top-[400px] -z-10 h-full w-full opacity-30 blur-[200px]",
        className,
      )}
      {...props}
    >
      <Icon Svg={BlobImage} size={1000} />
    </div>
  );
}

import { Outlet } from "react-router-dom";

import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { useDarkMode } from "~/hooks/useDarkMode";
import { Suspense } from "react";
import PageLoading from "~/components/common/PageLoading";

export default function AppPage() {
  const { isDarkMode } = useDarkMode();

  return (
    <>
      <Suspense fallback={<PageLoading />}>
        <Outlet />
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          newestOnTop
          theme={isDarkMode ? "dark" : "light"}
        />
      </Suspense>
    </>
  );
}

import { clsx } from "clsx";

const paddings = {
  none: "p-0",
  xs: "p-4",
  sm: "p-6",
  base: "p-8",
  lg: "p-16",
};

const colors = {
  default: "border-gray-300 dark:border-gray-600/40",
  primary: "border-blue-500 dark:border-blue-500/40",
  secondary: "border-gray-100 dark:border-gray-600/40",
  danger: "border-red-500 dark:border-red-500/40",
  warning: "border-yellow-500 dark:border-yellow-500/40",
  success: "border-green-500 dark:border-green-500/40",
};

export default function FlatCard({
  children,
  padding = "base",
  color = "default",
  className = "",
  ...props
}) {
  return (
    <div
      className={clsx(
        "rounded-lg border bg-white shadow dark:bg-gray-825",
        paddings[padding],
        colors[color],
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

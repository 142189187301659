import { Button, Timeline } from "flowbite-react";
import React from "react";
import Icon from "~/components/common/Icon";
import StepIndicators from "~/components/common/StepIndicators";
import NotionLogo from "~/assets/logos/notion.svg";
import Container from "~/components/common/Container";
import Page from "~/components/Page";
import TightCard from "~/components/common/TightCard";
import clsx from "clsx";
import { Alert } from "flowbite-react";

import ArrowRightIcon from "@/far/arrow-right.svg";
import ArrowLeftIcon from "@/far/arrow-left.svg";
import CheckCircleIcon from "@/far/check-circle.svg";
import CogIcon from "@/far/cog.svg";
import SparklesIcon from "@/fas/sparkles.svg";

import { useAuthContext } from "~/containers/AuthContainer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSidebarContentVisible } from "~/containers/AppContainer";
import PostCreateStatusItems from "~/components/connect/PostCreateStatusItems";
import {
  ConnectPageProvider,
  useConnectPageContext,
} from "~/components/connect/ConnectPageContainer";

import Step1NotionImage from "~/assets/img/how-to-click-next.png";
import Step2NotionImage from "~/assets/img/how-to-select-template.png";
import Subtitle from "~/components/common/Subtitle";

const NOTION_AUTH_URL = import.meta.env.VITE_NOTION_AUTHORIZATION_URL;
const ACCESS_DENIED = "access_denied";

function StepCard({ children, className = "" }) {
  return (
    <TightCard
      className={clsx(
        "flex w-full flex-row p-4 shadow-lg dark:!bg-gray-825 dark:shadow-2xl md:p-8",
        className,
      )}
    >
      {children}
    </TightCard>
  );
}

function Steppers({ currentStep, onChange }) {
  return (
    <div className="mt-8 flex flex-row items-center justify-between">
      {currentStep > 0 ? (
        <Button
          size="sm"
          outline
          color="purple"
          onClick={() => onChange(currentStep - 1)}
        >
          <Icon Svg={ArrowLeftIcon} className="mr-2" size={12} />
          Previous
        </Button>
      ) : (
        <div />
      )}

      {currentStep < 2 ? (
        <Button
          size="sm"
          outline
          color="purple"
          onClick={() => onChange(currentStep + 1)}
        >
          Next <Icon Svg={ArrowRightIcon} className="ml-2" size={12} />
        </Button>
      ) : (
        <a
          href="/list"
          className="text-sm text-gray-500 underline dark:text-gray-500"
        >
          Skip creating task list
        </a>
      )}
    </div>
  );
}

function Step1({ onChange }) {
  const { isNotionAuthenticated } = useAuthContext();

  return (
    <>
      <StepCard className="flex flex-col justify-center space-y-8">
        <Timeline>
          <Timeline.Item>
            <Timeline.Point />
            <Timeline.Content>
              <Timeline.Time>Step 1</Timeline.Time>
              <Timeline.Title>Click Next</Timeline.Title>
              <Timeline.Body>
                <div className="pt-4">
                  <img
                    src={Step1NotionImage}
                    className="w-full rounded shadow"
                  />
                </div>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <Timeline.Point />
            <Timeline.Content>
              <Timeline.Time>Step 2</Timeline.Time>
              <Timeline.Title>Select template</Timeline.Title>
              <Timeline.Body>
                <div className="pt-4">
                  <img
                    src={Step2NotionImage}
                    className="w-full rounded shadow"
                  />
                </div>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <Timeline.Point />
            <Timeline.Content>
              <Timeline.Title>Begin</Timeline.Title>
              <Timeline.Body>
                <div className="pt-4">
                  <Button
                    size="xl"
                    gradientDuoTone="purpleToBlue"
                    outline
                    fullSized={false}
                    href={NOTION_AUTH_URL}
                  >
                    <Icon
                      Svg={NotionLogo}
                      className="mr-4 fill-black dark:fill-black"
                      size={24}
                    />
                    Authenticate with Notion
                  </Button>
                </div>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
        </Timeline>
      </StepCard>
      {isNotionAuthenticated && (
        <Steppers currentStep={0} onChange={onChange} />
      )}
    </>
  );
}

function Step2({ onChange }) {
  return (
    <>
      <StepCard className="flex-col space-y-8">
        <PostCreateStatusItems />
      </StepCard>
      <Steppers currentStep={1} onChange={onChange} />
    </>
  );
}

function Step3({ onChange }) {
  const navigate = useNavigate();
  const { pageQuery } = useConnectPageContext();
  const { data: defaultTemplatePage } = pageQuery;

  return (
    <>
      <StepCard className="flex-col items-center justify-center space-y-8">
        <Icon
          Svg={CheckCircleIcon}
          className="fill-green-500 dark:fill-green-500"
          size={80}
        />

        <span className="text-xl">Notion successfully configured</span>

        <div className="flex flex-col items-center justify-center space-y-4 py-8">
          <div className="space-y-1 text-center">
            <Button
              size="sm"
              color="purple"
              fullSized
              onClick={() =>
                navigate("/list/create", {
                  state: {
                    autoCreate: true,
                    defaultSelectedPageId: defaultTemplatePage.id,
                  },
                })
              }
            >
              Auto create task list
              <Icon
                Svg={SparklesIcon}
                size={16}
                className="ml-2 fill-gray-300 dark:fill-gray-300"
              />
            </Button>
            <Subtitle>(Recommended for beginners)</Subtitle>
          </div>

          <Button
            size="sm"
            color="dark"
            fullSized
            onClick={() =>
              navigate("/list/create", {
                state: {
                  defaultSelectedPage: defaultTemplatePage,
                  hideCreateTemplateStep: true,
                },
              })
            }
          >
            Configure <Icon Svg={CogIcon} className="ml-2" size={12} />
          </Button>
        </div>
      </StepCard>
      <Steppers currentStep={2} onChange={onChange} />
    </>
  );
}

const STEPS = [Step1, Step2, Step3];
const STEP_NAMES = ["Connect", "Review", "Done"];

export default function ConnectPage() {
  const { isNotionAuthenticated } = useAuthContext();
  useSidebarContentVisible(isNotionAuthenticated);

  const [searchParams] = useSearchParams();

  const error = searchParams.get("error");
  const success = searchParams.get("success");

  const [step, setStep] = React.useState(
    isNotionAuthenticated || success ? 1 : 0,
  );

  const Step = STEPS[step];

  return (
    <ConnectPageProvider>
      <Page>
        <Container compact>
          <div className="m-auto w-full py-16 md:max-w-xl">
            {error && error !== ACCESS_DENIED && (
              <Alert className="mb-8" color="red">
                <strong>Error: </strong> {error}
              </Alert>
            )}

            <StepIndicators stepNames={STEP_NAMES} step={step} />

            <div className="pt-8">
              <Step onChange={setStep} />
            </div>
          </div>
        </Container>
      </Page>
    </ConnectPageProvider>
  );
}

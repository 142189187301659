import React from "react";
import { useDarkMode } from "~/hooks/useDarkMode";

export function useTheme() {
  const { isDarkMode } = useDarkMode();

  const theme = React.useMemo(
    () => ({
      dark: isDarkMode,
      theme: {
        alert: {
          color: {
            neutral:
              "text-gray-600 bg-gray-50 border-gray-400 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300",
          },
        },
        modal: {
          content: {
            inner:
              "relative rounded-lg bg-white shadow dark:bg-gray-825 flex flex-col max-h-[100vh]",
          },
        },
        button: {
          color: {
            default: "bg-purple-500 text-gray-300",
            primary: "bg-purple-500 text-gray-300",
            gray: "text-gray-900 bg-white dark:text-gray-300 border border-gray-300 enabled:hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 dark:bg-gray-800 dark:enabled:hover:bg-gray-700 dark:focus:ring-gray-800 dark:border-gray-700",
          },
          inner: {
            base: "flex transition-all duration-200 flex-row items-center",
          },
        },
        textInput: {
          field: {
            input: {
              colors: {
                gray: "bg-gray-50 border-gray-300 text-gray-900 focus:border-purple-500 focus:ring-purple-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-400 dark:focus:border-purple-500 dark:focus:ring-purple-500",
              },
            },
          },
        },
        textarea: {
          colors: {
            gray: "bg-gray-50 border-gray-300 text-gray-900 focus:border-purple-500 focus:ring-purple-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-400 dark:focus:border-purple-500 dark:focus:ring-purple-500",
          },
        },
      },
    }),
    [isDarkMode],
  );

  return theme;
}

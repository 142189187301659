import clsx from "clsx";
import ListGroup from "~/components/common/ListGroup";

export default function SettingSection({
  title,
  children,
  className = "",
  color = "default",
}) {
  children = Array.isArray(children) ? children : [children];
  return (
    <div className={clsx("space-y-3", className)}>
      <h1 className="text-2xl">{title}</h1>
      <ListGroup color={color}>
        {children.map((item, index) => (
          <ListGroup.Item key={index}>{item}</ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

import LoadingBlock from "~/components/common/LoadingBlock";

export default function Banner() {
  return (
    <div className="mb-8 flex justify-between">
      <LoadingBlock width="w-20" height="h-10" />

      <div className="space-x-4">
        <LoadingBlock width="w-20" height="h-10" />
        <LoadingBlock width="w-20" height="h-10" />
      </div>
    </div>
  );
}

import { Button } from "flowbite-react";
import TaskPreview from "./TaskPreview";
import TaskSuggestion from "./TaskSuggestion";
import CreateTask from "./CreateTask";
import Icon from "../common/Icon";

import PlusIcon from "@/fas/plus.svg";
import EyeIcon from "@/fas/eye.svg";
import PencilIcon from "@/fas/pencil.svg";
import SaveIcon from "@/fas/save.svg";
import SpinnerThirdIcon from "@/fad/spinner-third.svg";
import { useEmbedContext } from "~/containers/EmbedContainer";

function Actions() {
  const {
    preview,
    loading,
    error,
    setIsEditing,
    isEditing,
    enabled,
    addTask,
    previewTask,
  } = useEmbedContext();

  if (preview) {
    return (
      <div className="flex flex-row justify-end space-x-4">
        <Button
          color="light"
          onClick={() => setIsEditing(!isEditing)}
          disabled={loading}
        >
          {isEditing ? (
            <>
              <Icon Svg={SaveIcon} className="mr-2" size={12} />
              Save
            </>
          ) : (
            <>
              <Icon Svg={PencilIcon} className="mr-2" size={12} />
              Edit
            </>
          )}
        </Button>

        <Button
          gradientMonochrome="purple"
          outline={!preview}
          onClick={addTask}
          disabled={preview === null || loading}
        >
          <Icon Svg={PlusIcon} className="mr-3 fill-gray-300" size={12} />
          Save and add
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-row items-center justify-end space-x-2">
      {loading && (
        <div className="mr-2 flex flex-row items-center font-semibold italic text-gray-300">
          <Icon
            Svg={SpinnerThirdIcon}
            className="mr-2 animate-spin fill-purple-500"
            size={14}
          />

          <span>Thinking</span>
          <span className="animate-bounce">.</span>
          <span className="animate-bounce animation-delay-[150ms]">.</span>
          <span className="animate-bounce animation-delay-[300ms]">.</span>
        </div>
      )}

      {error && (
        <div className="mr-2 flex flex-row items-center text-sm font-medium text-red-500">
          Unable to complete request. Reword your query and try again.
        </div>
      )}

      <Button
        color="light"
        onClick={previewTask}
        disabled={!enabled || loading}
      >
        <Icon Svg={EyeIcon} className="mr-2" size={12} />
        Preview
      </Button>

      <Button
        gradientMonochrome="purple"
        outline={!preview}
        onClick={addTask}
        disabled={!enabled || loading}
      >
        {loading ? (
          <Icon
            Svg={SpinnerThirdIcon}
            className="animation-spin mr-2"
            size={12}
          />
        ) : (
          <Icon Svg={PlusIcon} className="mr-2" size={12} />
        )}
        Add
      </Button>
    </div>
  );
}

export default function Embed() {
  const { preview } = useEmbedContext();

  // TODO: Add a powered by label
  return (
    <div className="h-full space-y-4 px-3 py-4">
      <CreateTask />

      {preview && <TaskPreview />}

      <Actions />

      {!preview && <TaskSuggestion />}
    </div>
  );
}

/* eslint-disable no-undef */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectAuthEmulator, deleteUser, getAuth } from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  remove,
  push,
  connectDatabaseEmulator,
} from "firebase/database";
import { FIREBASE_CONFIGS } from "~/consts/firebaseConfigs";

const environment = import.meta.env.MODE;

console.log("Using environment: ", environment);

const firebaseConfig = FIREBASE_CONFIGS[environment];

if (!firebaseConfig) {
  throw new Error(`No firebase config found for environment.`);
}

// Initialize Firebase
export const App = initializeApp(firebaseConfig);
export const Database = getDatabase(App);
export const Auth = getAuth(App);
export const Analytics = getAnalytics(App);
export const Functions = getFunctions(App);

if (import.meta.env.MODE === "development") {
  console.log("Dev mode detected, connecting to emulators");

  connectDatabaseEmulator(Database, "localhost", 5002);
  connectFunctionsEmulator(Functions, "localhost", 5001);
  connectAuthEmulator(Auth, "http://localhost:5004");
}

let _currentUser;

export const setCurrentUser = (user) => {
  _currentUser = user;
};

export const currentUserSnapshot = () => _currentUser;

// Current user id should be available at the point of use
export const currentUserId = () => currentUserSnapshot()?.uid;

export const TaskListRef = (children = "") =>
  ref(Database, `task-lists/${children}`);

export const UsersRef = (children = "") =>
  ref(Database, `users/${currentUserId()}/${children}`);

export const UsersTaskListsRef = (children = "") =>
  ref(Database, `users-task-lists/${currentUserId()}/${children}`);

export const deleteAccount = async () => {
  const user = Auth.currentUser;

  await deleteUser(user);
}

export const taskLists = {
  insert: async (newTaskList) => {
    const { key } = await push(TaskListRef(), {
      ...newTaskList,
      creatorUid: currentUserId(),
    });

    return key;
  },
  update: async (taskList) => {
    if (!taskList?.id) {
      throw new Error("Task list id is required to update task list.");
    }

    const { id, ...rest } = taskList;
    const taskListRef = TaskListRef(id);
    await set(taskListRef, rest);
  },
  delete: async (taskListId) => {
    const taskListRef = TaskListRef(taskListId);
    await remove(taskListRef);
  },
};

export const users = {
  update: async (user) => {
    if (!user?.id) {
      throw new Error("User id is required to update user.");
    }

    const userRef = UsersRef();
    await set(userRef, user);
  },
};
// Container for the task list

import React from "react";
import * as notionService from "../services/notion";
import * as firebaseService from "../services/firebase";
import { useAuthContext } from "./AuthContainer";
import { useDatabasesQuery } from "~/hooks/useDatabasesQuery";
import useManagedCallback from "~/hooks/useManagedCallback";

const ProjectContext = React.createContext({
  taskLists: {},
  isTaskListLoading: true,
  createTaskList: async (_params) => null,
  deleteTaskList: async (_params) => {},
});

export function useProjectContext() {
  const context = React.useContext(ProjectContext);

  if (context === undefined) {
    throw new Error("useProjectContext must be used within an ProjectContext");
  }

  return context;
}

export function ProjectProvider({ children }) {
  const { googleUser } = useAuthContext();
  const userId = googleUser?.uid;

  if (!userId) {
    // Suspend this component until the user is logged in //
    throw new Promise(() => {});
  }

  const { data: _usersTaskLists, isLoading: isUsersTaskListsLoading } =
    useDatabasesQuery({
      queryKey: ["usersTaskLists", userId],
      databaseRefs: [firebaseService.UsersTaskListsRef()],
    });

  const usersTaskLists = _usersTaskLists[userId];

  const userTaskListsIds = Object.keys(usersTaskLists ?? {}).filter(
    (listId) => !listId.startsWith("_"),
  );

  const { data: taskLists, isLoading: isTaskListLoading } = useDatabasesQuery({
    queryKey: ["taskLists", userTaskListsIds.join(",")],
    databaseRefs: userTaskListsIds.map((taskListId) =>
      firebaseService.TaskListRef(taskListId),
    ),
    mapperFn: (data, snapshot) => ({
      id: snapshot.key,
      ...data,
    }),
    options: {
      enabled: !isUsersTaskListsLoading,
    },
  });

  const createTaskList = useManagedCallback(
    async ({ pageId, pageUrl, databaseId, name }) => {
      // Run the updating and creation in parallel
      const newTaskListId = await firebaseService.taskLists.insert({
        // TODO: Make this a nested object
        notionPageId: pageId,
        notionDatabaseId: databaseId,
        notionPageUrl: pageUrl,
        name,
      });

      // TODO: Enrich this new task using OpenAI

      await notionService.createDatabaseEntry(databaseId, {
        title: "Setup Notion Task List",
        label: "Personal",
        description: "This is the first task in your new task list!",
        duration: 5,
        startTime: new Date().toISOString(),
      });

      return {
        id: newTaskListId,
        notionPageId: pageId,
        notionDatabaseId: databaseId,
        notionPageUrl: pageUrl,
        name,
      };
    },
    [],
  );

  const deleteTaskList = useManagedCallback(
    async ({
      taskList = undefined,
      taskListId = undefined,
      archiveNotionPage = false,
    }) => {
      if (!taskList && !taskListId) {
        throw new Error("Must provide either taskList or taskListId");
      }

      taskList = taskList || taskLists[taskListId];

      if (!taskList) {
        throw new Error(`Task list ${taskListId} not found`);
      }

      if (archiveNotionPage) {
        await notionService.archivePage(taskList.notionPageId);
      }

      await firebaseService.taskLists.delete(taskList.id);
    },
    [taskLists],
  );

  if (isTaskListLoading) {
    return null;
  }

  return (
    <ProjectContext.Provider
      value={{
        taskLists: taskLists || {},
        isTaskListLoading: false,
        createTaskList,
        deleteTaskList,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

import CheckCircleIcon from "@/far/check-circle.svg";
import ExclamationCircleIcon from "@/far/exclamation-circle.svg";
import TimesCircleIcon from "@/far/times-circle.svg";
import CircleIcon from "@/far/circle.svg";
import SpinnerThirdIcon from "@/far/spinner-third.svg";
import Icon from "~/components/common/Icon";
import clsx from "clsx";
import { useAuthContext } from "~/containers/AuthContainer";
import { useConnectPageContext } from "~/components/connect/ConnectPageContainer";
import HelpTip from "~/components/common/HelpTip";
import LinkButton from "~/components/common/LinkButton";
import useManagedCallback from "~/hooks/useManagedCallback";

export default function PostCreateStatusItems() {
  const { loggedInUser } = useAuthContext();
  const { notion } = loggedInUser || {};
  const { workspaceName, duplicatedTemplateId } = notion || "no workspace name";

  const { pageQuery, databaseQuery, clearNotionCredentials } =
    useConnectPageContext();

  const pageId = pageQuery?.data?.id.replace(/-/g, "");
  const databaseId = databaseQuery?.data?.id.replace(/-/g, "");

  const hasTemplatePage = Boolean(duplicatedTemplateId);

  const tryAgain = useManagedCallback(() => {
    pageQuery.refetch();
    databaseQuery.refetch();
  }, [pageQuery, databaseQuery]);

  const pageStatus = (() => {
    if (!hasTemplatePage) return "missing";
    if (pageQuery.isError) return "error";
    if (pageQuery?.data?.archived) return "archived";
    if (pageQuery.isLoading) return "loading";
    if (pageId) return "found";
    return "missing";
  })();

  const databaseStatus = (() => {
    if (databaseQuery.isError || pageQuery.isError) return "error";
    if (databaseQuery.isLoading) return "loading";
    if (databaseId) return "found";
    return "missing";
  })();

  const items = [
    {
      status: "success",
      content: "Connected to Notion.",
    },
    {
      status: "success",
      content: (
        <span>
          Using workspace{" "}
          <a
            href={`https://notion.so/${workspaceName}`}
            rel="noopener noreferrer"
            target="_blank"
            className="underline"
          >
            {workspaceName}
          </a>
          .
        </span>
      ),
    },
    {
      isLoading: pageStatus === "loading",
      status: {
        loading: "info",
        error: "error",
        found: "success",
        archived: "warning",
        missing: "warning",
      }[pageStatus],
      content: {
        loading: (
          <span>
            Fetching Notion page details...{" "}
            {pageQuery.failureCount >= 5 && "(may take a while)"}
          </span>
        ),
        error: (
          <span>
            There was an error fetching the Notion page.{" "}
            <LinkButton onClick={tryAgain}>Try again.</LinkButton>
          </span>
        ),
        found: (
          <span>
            <a
              href={`https://notion.so/${pageId}`}
              rel="noopener noreferrer"
              target="_blank"
              className="underline"
            >
              Page
            </a>{" "}
            created from template.
          </span>
        ),
        missing: (
          <span>
            No page created from template.{" "}
            <HelpTip>
              <div className="space-y-2 px-4 py-2">
                <p>
                  During Notion authentication, no page was created. You will
                  have to:
                </p>
                <ul className="list-disc pl-4">
                  <li>Manually link a page, or</li>
                  <li>Go back and create a page from a template</li>
                </ul>
              </div>
            </HelpTip>
          </span>
        ),
        archived: (
          <span>
            <a
              href={`https://notion.so/${pageId}`}
              rel="noopener noreferrer"
              target="_blank"
              className="underline"
            >
              Page
            </a>{" "}
            created from template but archived.
          </span>
        ),
      }[pageStatus],
    },
    hasTemplatePage
      ? {
          isLoading: databaseStatus === "loading",
          status: {
            loading: "info",
            error: "error",
            found: "success",
            archived: "warning",
            missing: "warning",
          }[databaseStatus],
          content: {
            loading: <span>Fetching Notion database details...</span>,
            error: (
              <span>
                There was an error fetching the database.{" "}
                <LinkButton onClick={tryAgain}>Try again.</LinkButton>
              </span>
            ),
            found: (
              <span>
                <a
                  href={`https://notion.so/${pageId}`} // Keep it as the page. Thats the only way you can access it. (kinda) //
                  rel="noopener noreferrer"
                  target="_blank"
                  className="underline"
                >
                  Database
                </a>{" "}
                created from template.
              </span>
            ),
            missing: <span>No Notion database was attached to page.</span>,
          }[databaseStatus],
        }
      : null,
    pageQuery.error?.toString().includes("API token is invalid") && {
      status: "warning",
      content: (
        <span>
          You may not be authenticated.{" "}
          <LinkButton onClick={clearNotionCredentials}>Link again.</LinkButton>
        </span>
      ),
    },
  ];

  return (
    <ul>
      {items
        .filter((b) => b)
        .map((item, index) => (
          <li
            key={index}
            className="mb-4 flex flex-row items-center justify-start"
          >
            <Icon
              Svg={
                item.isLoading
                  ? SpinnerThirdIcon
                  : {
                      success: CheckCircleIcon,
                      error: TimesCircleIcon,
                      warning: ExclamationCircleIcon,
                    }[item.status] || CircleIcon
              }
              className={clsx(
                "mr-4",
                item.status === "success" &&
                  "fill-green-500 dark:fill-green-400",
                item.status === "warning" &&
                  "fill-yellow-500 dark:fill-yellow-400",
                item.status === "error" && "fill-red-500 dark:fill-red-400",
                item.isLoading &&
                  "animate-spin fill-purple-500 dark:fill-purple-500",
              )}
              size={16}
            />
            {item.content}
          </li>
        ))}
    </ul>
  );
}

import clsx from "clsx";

export default function TightCard({ children, className = "", ...props }) {
  return (
    <div
      className={clsx(
        "rounded-lg border border-gray-300/50 bg-white p-4 dark:border-gray-200/10 dark:bg-gray-875",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

import { useQuery } from "@tanstack/react-query";
import * as notionService from "~/services/notion";

export default function useNotion(pageId, options) {
  const pageQuery = useQuery(
    ["notionPage", pageId],
    () => notionService.fetchPage(pageId),
    {
      enabled: Boolean(pageId),
      suspense: false,
      useErrorBoundary: false,
      retryOnMount: true,
      retry: 5,
      retryDelay: 1500,
      staleTime: Infinity,

      ...options,
    },
  );

  const databaseQuery = useQuery(
    ["notionDatabaseFromPage", pageQuery?.data?.id],
    async () => {
      if (pageQuery?.data?.archived) {
        return null;
      }

      const blocks = await notionService.fetchBlocks(pageQuery?.data?.id);

      // TODO: Handle multiple databases

      const databaseBlock = blocks?.results?.find(
        (block) => block.type === "child_database",
      );

      if (!databaseBlock?.id) {
        return null;
      }

      return notionService.fetchDatabase(databaseBlock.id);
    },
    {
      enabled: Boolean(pageQuery?.data?.id && !pageQuery?.isError),
      suspense: false,
      useErrorBoundary: false,
      retryOnMount: true,
      retry: 3,
      retryDelay: 1500,
      staleTime: Infinity,

      ...options,
    },
  );

  return { pageQuery, databaseQuery };
}

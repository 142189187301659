import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

// Pages //
import AppPage from "~/pages/AppPage";
import LoginPage from "~/pages/LoginPage";
import CreateTaskListPage from "~/pages/CreateTaskListPage";
import EmbedPage from "~/pages/EmbedPage";
import AuthLayoutRoute from "~/components/AuthLayoutRoute";
import TaskListsPage from "~/pages/TaskListsPage";
import SingleTaskListPage from "~/pages/SingleTaskListPage";
import ConnectPage from "~/pages/ConnectPage";
import CallbackPage, { CallbackLoaders } from "~/pages/CallbackPage";
import ErrorPage from "~/pages/ErrorPage";
import SettingsPage from "~/pages/SettingsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/list" />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "embed",
        element: <EmbedPage />,
      },
      {
        element: <AuthLayoutRoute />,
        children: [
          {
            path: "connect",
            children: [
              {
                index: true,
                element: <ConnectPage />,
              },
              {
                path: "notion/callback",
                loader: CallbackLoaders.notion,
                element: <CallbackPage />,
              },
            ],
          },
          {
            path: "list",
            children: [
              {
                index: true,
                element: <TaskListsPage />,
              },
              {
                path: ":listId",
                element: <SingleTaskListPage />,
              },
              {
                path: "create",
                element: <CreateTaskListPage />,
              },
            ],
          },
          {
            path: "settings",
            element: <SettingsPage />,
          },
        ],
      },
    ],
  },
]);

export default function AppRoutes() {
  return <RouterProvider router={router} />;
}

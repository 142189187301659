import clsx from "clsx";
import Icon from "~/components/common/Icon";
import CloseIcon from "@/fas/times.svg";

export default function CloseButton({ onClose, className = "" }) {
  return (
    <button
      onClick={onClose}
      className={clsx("group cursor-pointer", className)}
    >
      <Icon
        Svg={CloseIcon}
        size={14}
        className="fill-gray-500 group-hover:fill-black dark:fill-gray-500 group-hover:dark:fill-gray-300"
      />
    </button>
  );
}

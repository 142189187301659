import { Card } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const taskListItemTheme = (interactive = true) => ({
  root: {
    base: clsx(
      "flex rounded-lg border border-gray-300 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800",
      interactive && "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer",
    ),
    children: "flex h-full flex-col justify-center gap-4 p-0",
  },
});

export default function TaskListItem({ taskList, interactive = true }) {
  // TODO: Show emoji
  // TODO: Show last updated
  // TODO: Show number of tasks
  // TODO: Show number of completed tasks
  // TODO: Show number of uncompleted tasks
  // TODO: Show number of tasks due today
  // TODO: Show quick link to open in notion

  const { id, name } = taskList;

  const navigate = useNavigate();

  const handleCardClicked = (e) => {
    if (!interactive) {
      e.preventDefault();
      return;
    }

    if (e.target.closest("a")) {
      // Let the link handle the click //
      return;
    }

    navigate(`/list/${id}`);
  };

  return (
    <Card theme={taskListItemTheme(interactive)} onClick={handleCardClicked}>
      <div className="group/item flex w-full flex-row items-center justify-between p-6">
        <p className={clsx(interactive && "group-hover/item:underline")}>
          {name}
        </p>
      </div>
    </Card>
  );
}

import { Card } from "flowbite-react";
import LoadingBlock from "~/components/common/LoadingBlock";

export default function TaskListItemLoading() {
  return (
    <Card>
      <LoadingBlock width="w-full" height="h-5" />
    </Card>
  );
}

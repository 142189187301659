import { useRollbar } from "@rollbar/react";
import React from "react";
import { toast } from "react-toastify";
import { useTaskContext } from "~/containers/TaskContainer";

// TODO: Use react-query more
// TODO: Move to just the embed page

const EmbedContext = React.createContext({
  preview: null,
  setPreview: (_preview) => {},
  loading: false,
  setLoading: (_loading) => {},
  error: null,
  setError: (_error) => {},
  isEditing: false,
  setIsEditing: (_isEditing) => {},
  enabled: false,
  addTask: () => {},
  previewTask: () => {},
  inputValue: "",
  setInputValue: (_inputValue) => {},
});

export const EmbedProvider = ({ children }) => {
  const rollbar = useRollbar();
  const taskContainer = useTaskContext();
  const [error, setError] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [preview, setPreview] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");

  const enabled = inputValue.length > 0;

  const previewTask = React.useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const preview = await taskContainer.previewTask(inputValue);

      setPreview(preview);

      // N.b Don't clear the input field
    } catch (error) {
      rollbar.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [taskContainer, inputValue, rollbar]);

  const addTask = React.useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      if (!preview) {
        await taskContainer.createTaskWithAI({
          query: inputValue,
        });
      } else {
        await taskContainer.createTask(preview);
        setPreview(null);
      }

      toast.success("Task created!");

      setInputValue("");
    } catch (error) {
      rollbar.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [preview, taskContainer, inputValue, rollbar]);

  return (
    <EmbedContext.Provider
      value={{
        preview,
        setPreview,
        loading,
        setLoading,
        error,
        setError,
        isEditing,
        setIsEditing,
        enabled,
        addTask,
        previewTask,
        inputValue,
        setInputValue,
      }}
    >
      {children}
    </EmbedContext.Provider>
  );
};

export const useEmbedContext = () => {
  const context = React.useContext(EmbedContext);

  if (context === undefined) {
    throw new Error("useEmbedContext must be used within an EmbedProvider");
  }

  return context;
};

import SettingSection from "~/components/settings/SettingSection";
import ButtonConfig from "~/components/settings/forms/ButtonConfig";

export default function AccountDetailsPane() {
  return (
    <SettingSection title="Account">
      <ButtonConfig
        label="Update"
        title="Update account details"
        description="The account details are pulled from your Google Account"
        buttonProps={{
          href: "https://myaccount.google.com/personal-info",
          target: "_blank",
          rel: "noopener noreferrer",
        }}
      />
    </SettingSection>
  );
}

// Takes in an SVG and renders a square icon

import clsx from "clsx";

// TODO: Convert numeric size to tailwind sizing
// TODO: Use lazy loading to load SVGs
export default function Icon({ Svg, size = 16, className = "", ...props }) {
  return (
    <span className="inline-block fill-gray-800 leading-[0] dark:fill-gray-300">
      <Svg
        className={clsx("inline-block", className)}
        width={size}
        height={size}
        {...props}
      />
    </span>
  );
}

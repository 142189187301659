import { Client } from "@notionhq/client";
import { httpsCallable } from "firebase/functions";
import { Functions } from "./firebase";
import { capitalize } from "lodash";

/**
 * Extracted from functions.
 * 
 * @param {*} param0 
 * @returns 
 */
// TODO: Make this more hook-like
export async function doNotionAction({ type, action, params = {} }) {
  const notionClient = new Client({
    auth: localStorage.getItem("notionAccessToken"),
    baseUrl: "https://notion-proxy.getfrankie.app", // Proxy through our own backend to avoid CORS issues.
  });

  let notionAction = notionClient[type];

  if (action) {
    const actions = action.split(".");

    // Walk through the action path //
    actions.forEach((action) => {
      notionAction = notionAction[action];
    });
  }

  return await notionAction(params);
}

export async function completeAuthFlow({ code, error }) {
  if (error === "access_denied") {
    // TODO: Handle this error.
    throw new Error("Notion access denied");
  }

  const notionAuthCallbackFunction = httpsCallable(Functions, "notionAuthCallback");
  const response = await notionAuthCallbackFunction({
    code,
  });

  // @ts-ignore
  if (!response?.data?.result?.success) {
    // TODO: Handle this error.
    throw new Error("Failed to authenticate with Notion");
  }
}

/**
 * @returns {Promise<import("@notionhq/client/build/src/api-endpoints").CreatePageResponse>}
 */
export async function createDatabaseEntry(notionDatabaseId, entryData, properties = {}) {
  const { title, label, description, duration, startTime } =
    entryData;

  return doNotionAction({
    type: "pages",
    action: "create",
    params: {
      parent: {
        database_id: notionDatabaseId,
      },
      properties: {
        Title: {
          type: "title",
          title: [
            {
              type: "text",
              text: {
                content: title,
              },
            },
          ],
        },
        Label: {
          type: "select",
          select: {
            name: capitalize(label),
          },
        },
        Description: {
          type: "rich_text",
          rich_text: [
            {
              type: "text",
              text: {
                content: description,
              },
            },
          ],
        },
        Duration: {
          type: "number",
          number: duration,
        },
        "Start Time": {
          type: "date",
          date: {
            start: startTime,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone, // TODO: Use the user's timezone.
          },
        },

        ...properties,
      },
    },
  });
}


/**
 * @returns {Promise<import("@notionhq/client/build/src/api-endpoints").PageObjectResponse>}
 */
export async function createPage(notionPageId, title, children = [], _properties = {}) {
  return doNotionAction({
    type: "pages",
    action: "create",
    params: {
      parent: {
        page_id: notionPageId,
      },
      icon: {
        type: "emoji",
        emoji: "📝",
      },
      properties: {
        title: [
          {
            text: {
              content: title,
            },
          },
        ],
      },
      children,
    },
  });
}

/**
 * @returns {Promise<import("@notionhq/client/build/src/api-endpoints").UpdatePageResponse>}
 */
export async function updatePageProperties(notionPageId, properties) {
  return doNotionAction({
    type: "pages",
    action: "update",
    params: {
      page_id: notionPageId,
      properties,
    },
  });
}

/**
 * @returns {Promise<import("@notionhq/client/build/src/api-endpoints").AppendBlockChildrenResponse>}
 */
export async function appendPageBlocks(notionPageId, children, after = undefined) {

  let additionalParams = {};

  if (after) {
    additionalParams = {
      after,
    };
  }

  return doNotionAction({
    type: "blocks",
    action: "children.append",
    params: {
      block_id: notionPageId,
      children,
      ...additionalParams,
    },
  });
}

/**
 * 
 * @param {*} notionPageId 
 * @param {*} properties 
 * @returns {Promise<import("@notionhq/client/build/src/api-endpoints").CreateDatabaseResponse>}
 */
export async function createDatabase(notionPageId, title, properties, inline = true) {
  return doNotionAction({
    type: "databases",
    action: "create",
    params: {
      parent: {
        page_id: notionPageId,
      },
      title: [
        {
          type: "text",
          text: {
            content: title,
          },
        },
      ],
      is_inline: inline,
      properties,
    },
  });
}

export async function archivePage(notionPageId) {
  return doNotionAction({
    type: "pages",
    action: "update",
    params: {
      page_id: notionPageId,
      archived: true,
    },
  });
}

export async function fetchBlocks(notionPageId) {
  return doNotionAction({
    type: "blocks",
    action: "children.list",
    params: {
      block_id: notionPageId,
      page_size: 100,
    },
  });
}

/** @type {(notionPageId: string) => Promise<import("@notionhq/client/build/src/api-endpoints").PageObjectResponse>} */
export async function fetchPage(notionPageId) {
  return doNotionAction({
    type: "pages",
    action: "retrieve",
    params: {
      page_id: notionPageId,
    },
  });
}

/** @type {(notionDatabaseId: string) => Promise<import("@notionhq/client/build/src/api-endpoints").DatabaseObjectResponse>} */
export async function fetchDatabase(notionDatabaseId) {
  return doNotionAction({
    type: "databases",
    action: "retrieve",
    params: {
      database_id: notionDatabaseId,
    },
  });
}

// TODO: Handle pagination.
export async function search(query, sort = undefined, filter = undefined) {
  return doNotionAction({
    type: "search",
    params: {
      query,
      sort: sort || {
        direction: "ascending",
        timestamp: "last_edited_time",
      },
      filter: filter || {
        value: "page",
        property: "object",
      }
    },
  });
}

export async function restoreEmbeds(listId, pageId) {
  const restoreEmbedsFunction = httpsCallable(Functions, "restoreEmbeds");
  const response = await restoreEmbedsFunction({
    listId,
    pageId,
  });

  // @ts-ignore
  return response?.data?.result;
}

// TODO: Move all block creations to this const.
// TODO: Move this const to a separate file.
export const blocks = {
  createCallout: (title, text, color = "blue_background", emoji = "🚧") => ({
    type: "callout",
    callout: {
      rich_text: [
        {
          text: {
            content: title,
            link: null,
          },
          annotations: {
            bold: true,
          },
        },
        {
          text: {
            content: `\n${text}`,
            link: null,
          },
        },
      ],
      icon: {
        type: "emoji",
        emoji,
      },
      color,
    }
  }),
};
import "./App.css";
import AppRoutes from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Flowbite } from "flowbite-react";

import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
} from "@rollbar/react";

import { HelmetProvider } from "react-helmet-async";
import { AppProvider } from "~/containers/AppContainer";
import { useTheme } from "~/hooks/useTheme";
import ErrorPage from "~/pages/ErrorPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      suspense: true,
      useErrorBoundary: true,
      networkMode: "offlineFirst",
    },
  },
});

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT,
};

function Content() {
  // TODO: Reduce duplication. The styles are merged with the default theme, no need to repeat
  const theme = useTheme();

  return (
    <RollbarProvider config={rollbarConfig}>
      <RollbarErrorBoundary fallbackUI={(error) => <ErrorPage error={error} />}>
        <HelmetProvider>
          <Flowbite theme={theme}>
            <QueryClientProvider client={queryClient}>
              <AppRoutes />
            </QueryClientProvider>
          </Flowbite>
        </HelmetProvider>
      </RollbarErrorBoundary>
    </RollbarProvider>
  );
}

function App() {
  return (
    <AppProvider>
      <Content />
    </AppProvider>
  );
}

export default App;

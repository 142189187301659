import clsx from "clsx";
import { Button } from "flowbite-react";

export default function IconButton({ children, className = "", ...props }) {
  return (
    <Button size="xl" color="gray" className={clsx("cursor-pointer disabled:cursor-not-allowed", className)} {...props}>
      {children}
    </Button>
  );
}

import Icon from "~/components/common/Icon";
import NotionLogo from "~/assets/logos/notion.svg";
import FlatCard from "~/components/common/FlatCard";
import { Button, Dropdown } from "flowbite-react";
import ExternalLinkIcon from "@/fas/external-link-alt.svg";
import { useTaskContext } from "~/containers/TaskContainer";
import NotionPageStatus from "~/components/common/integration/notion/NotionPageStatus";
import SyncIcon from "@/far/sync.svg";
import EllipsisVIcon from "@/far/ellipsis-v.svg";
import React from "react";

function Item({ LogoSvg, name, actions = null, children }) {
  return (
    <FlatCard className="relative w-72" padding="sm">
      <div className="space-y-8">
        <div className="flex flex-row items-center justify-between border-b border-gray-300 pb-4 dark:border-gray-700">
          <div className="text-xl font-black tracking-normal">{name}</div>
          <div>{actions}</div>
        </div>
        <div className="flex flex-col space-y-8">
          <Icon Svg={LogoSvg} className="fill-black" size={80} />
          {children}
        </div>
      </div>
    </FlatCard>
  );
}

function NotionItem() {
  const { taskList, restoreEmbeds } = useTaskContext();
  const { notionPageUrl: _notionPageUrl } = taskList || {};
  const notionPageUrl = _notionPageUrl.replace("https://", "notion://");

  const Actions = React.useMemo(() => {
    return (
      <Dropdown
        label=""
        dismissOnClick
        renderTrigger={() => (
          <Button size="xs" color="neutral">
            <Icon Svg={EllipsisVIcon} size={20} />
          </Button>
        )}
      >
        <Dropdown.Item onClick={restoreEmbeds}>
          <Icon Svg={SyncIcon} className="mr-4" size={12} />
          Restore Embeds
        </Dropdown.Item>
      </Dropdown>
    );
  }, [restoreEmbeds]);

  return (
    <Item name="Notion" LogoSvg={NotionLogo} actions={Actions}>
      <div className="flex w-full flex-col space-y-12">
        <Button
          color="purple"
          href={notionPageUrl}
          rel="noopener noreferrer"
          target="_blank"
          size="sm"
        >
          Open in notion{" "}
          <Icon
            Svg={ExternalLinkIcon}
            size={12}
            className="ml-2 fill-gray-300 dark:fill-gray-300"
          />
        </Button>

        <NotionPageStatus notionPageId={taskList?.notionPageId} />
      </div>
    </Item>
  );
}

export default function Integrations() {
  return (
    <div className="flex flex-row flex-wrap gap-4">
      <NotionItem />
    </div>
  );
}

import clsx from "clsx";
import { Tooltip } from "flowbite-react";
import Icon from "~/components/common/Icon";

import QuestionCircleIcon from "@/far/question-circle.svg";

const theme = {
  target: "inline-block",
};

export default function HelpTip({ children, className = "", ...props }) {
  return (
    <Tooltip content={children} theme={theme}>
      <div>
        <Icon
          Svg={QuestionCircleIcon}
          size={16}
          className={clsx(
            "inline-block fill-gray-500 dark:fill-gray-500",
            className,
          )}
          {...props}
        />
      </div>
    </Tooltip>
  );
}

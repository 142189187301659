import { Button, Card } from "flowbite-react";
import Icon from "../common/Icon";
import RefreshIcon from "@/fas/redo.svg";

export default function EmbedError({ error }) {
  // TODO: Link to specific list page
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Card className="w-full max-w-lg text-center">
        <div className="mb-12">
          <h3 className="mb-2 font-black">There was a problem</h3>
          <p className="text-sm">
            {error.message || error.error?.toString() || error.toString()}
          </p>
        </div>

        <div className="flex flex-row justify-between">
          <Button
            color="light"
            target="_blank"
            rel="noopener noreferrer"
            fullSized={false}
            onClick={() => window.location.reload()}
          >
            <Icon Svg={RefreshIcon} className="mr-3" size={12} />
            <p>Back</p>
          </Button>
        </div>
      </Card>
    </div>
  );
}

import { Button } from "flowbite-react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import Container from "~/components/common/Container";
import Icon from "~/components/common/Icon";
import EmptyTaskList from "~/components/task-list/EmptyTaskList";
import TaskListItem from "~/components/task-list/TaskListItem";
import { useProjectContext } from "~/containers/ProjectContainer";
import Plus from "@/far/plus.svg";
import Subtitle from "~/components/common/Subtitle";
import Page from "~/components/Page";

export default function TaskListsPage() {
  // TODO: Add a "help getting started" section

  const { taskLists } = useProjectContext();
  const navigate = useNavigate();

  const entries = Object.entries(taskLists);

  return (
    <Page>
      <Container
        title="Task Lists"
        compact={!isEmpty(taskLists)}
        className="py-16"
        actions={() => (
          <Button
            onClick={() => navigate("/list/create")}
            pill
            size="sm"
            color="purple"
          >
            Create
            <span className="hidden sm:ml-1.5 sm:inline">Task List</span>
            <Icon Svg={Plus} className="ml-2 fill-gray-300" />
          </Button>
        )}
      >
        {isEmpty(taskLists) ? (
          <EmptyTaskList />
        ) : (
          <div className="w-full space-y-4">
            <Subtitle>
              {entries.length} {entries.length === 1 ? "result" : "results"}
            </Subtitle>
            {entries.map(([id, taskList]) => (
              <TaskListItem key={id} taskList={taskList} />
            ))}
          </div>
        )}
      </Container>
    </Page>
  );
}

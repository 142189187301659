import clsx from "clsx";

export default function Title({ children, className = "", ...props }) {
  return (
    <h1
      className={clsx("text-3xl font-black", className)}
      {...props}
    >
      {children}
    </h1>
  );
}

import { TaskProvider } from "~/containers/TaskContainer";
import Banner from "./Banner";
import Page from "~/components/Page";
import Integrations from "~/components/single-task-list/Integrations";
import Container from "~/components/common/Container";

function Content() {
  return (
    <>
      <Banner />
      <Container className="py-16">
        <Integrations />
      </Container>
    </>
  );
}

export default function SingleTaskList({ taskListId }) {
  return (
    <TaskProvider taskListId={taskListId}>
      <Page>
        <Content />
      </Page>
    </TaskProvider>
  );
}

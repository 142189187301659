export default function ErrorText({
  children,
  fallback,
  forceFallback = false,
  hideReloadLink = false,
  ...props
}) {
  const reloadLink = !hideReloadLink && (
    <>
      {" "}<span
        className="cursor-pointer underline"
        onClick={() => window.location.reload()}
      >
        Refresh and try again
      </span>
      .
    </>
  );

  if ((import.meta.env.DEV || !fallback) && !forceFallback) {
    return (
      <div {...props}>
        {children}
        {reloadLink}
      </div>
    );
  }

  return (
    <div {...props}>
      {fallback}
      {reloadLink}
    </div>
  );
}

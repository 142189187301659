import { Button, Label, Select, TextInput } from "flowbite-react";
import NotionBadge from "../common/integration/notion/NotionBadge";
import React from "react";
import { NOTION_SELECT_COLORS } from "~/consts/notionColors";
import { capitalize } from "lodash";

export default function LabelRow({
  label,
  hasConflict,
  onSave,
  onDelete,
  readonly,
}) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [updatedLabel, setUpdatedLabel] = React.useState(label);

  return (
    <div className="border-black/8 w-full border-b p-4 first:border-t dark:border-white/10">
      <div className="columns-2">
        <div>
          <NotionBadge className="inline" label={updatedLabel} />
        </div>
        <div className="w-full"></div>
        {!readonly && (
          <>
            <div className="flex flex-row justify-end">
              {!isEditing && (
                <Button
                  className="mr-2"
                  size="xs"
                  color="light"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              )}
              <Button size="xs" color="failure" onClick={onDelete}>
                Delete
              </Button>
            </div>
          </>
        )}
      </div>

      {isEditing && (
        <div className="mt-3 columns-3">
          <div>
            <Label htmlFor="labelName" value="Label Name" />
            <TextInput
              id="labelName"
              className="w-full"
              shadow
              value={updatedLabel.name}
              onChange={(e) =>
                setUpdatedLabel({ ...updatedLabel, name: e.target.value })
              }
            />
          </div>

          <div>
            <Label htmlFor="labelColor" value="Color" />
            <Select
              id="labelColor"
              className="w-full"
              shadow
              value={updatedLabel.color}
              onChange={(e) =>
                setUpdatedLabel({ ...updatedLabel, color: e.target.value })
              }
            >
              {Object.entries(NOTION_SELECT_COLORS).map(([name]) => (
                <option key={name} value={name}>
                  {capitalize(name)}
                </option>
              ))}
            </Select>
          </div>
          <div className="flex flex-row items-end justify-end">
            <Button
              className="mr-2"
              color="light"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
            <Button
              color="success"
              disabled={hasConflict(updatedLabel.name) || !updatedLabel.name}
              onClick={() => {
                onSave(updatedLabel);
                setIsEditing(false);
              }}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

import clsx from "clsx";

export default function StepIndicators({ stepNames, step }) {
  return (
    <ol className="flex w-full items-center text-center text-sm font-medium text-gray-500 dark:text-gray-400 sm:text-base">
      {stepNames.map((name, index) => {
        const isActive = index === step;
        const isComplete = index < step;
        const lastChild = index === stepNames.length - 1;

        const colors =
          isComplete || isActive
            ? ["text-purple-600", "dark:text-purple-500"]
            : [];

        if (lastChild) {
          return (
            <li
              key={name}
              className={clsx("flex items-center", ...colors)}
            >
              <span className="mr-2">{index + 1}.</span>
              {name}
            </li>
          );
        }

        return (
          <li
            key={name}
            className={clsx(
              "after:border-1 flex items-center after:mx-4 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200 dark:after:border-gray-700 sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-6",
              ...colors,
            )}
          >
            <span className="flex flex-[1_0_auto] items-center after:mx-2 after:text-gray-200 after:content-['/'] dark:after:text-gray-500 sm:after:hidden">
              {isComplete ? (
                <CheckIcon />
              ) : (
                <span className="mr-2">{index + 1}.</span>
              )}

              {name}
            </span>
          </li>
        );
      })}
    </ol>
  );
}

function CheckIcon() {
  return (
    <svg
      className="mr-2.5 h-3.5 w-3.5 sm:h-4 sm:w-4"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
    </svg>
  );
}

const productionConfig = {
  apiKey: "AIzaSyA_3xXZvBtNgR8vcJqQWdAkRFM0-E_Wxo0",
  authDomain: "app.getfrankie.app",
  databaseURL: "https://frankieai-prod-default-rtdb.firebaseio.com",
  projectId: "frankieai-prod",
  storageBucket: "frankieai-prod.appspot.com",
  messagingSenderId: "1048127564159",
  appId: "1:1048127564159:web:a09a571c92f7d5c46b524a",
  measurementId: "G-WZWMWBMPKD"
};

const stageConfig = {
  apiKey: "AIzaSyB28rqcTF12SscHU9I6lENcWqy-sWfWsCI",
  authDomain: "stage.getfrankie.app",
  databaseURL: "https://frankie-lumps-default-rtdb.firebaseio.com",
  projectId: "frankie-lumps",
  storageBucket: "frankie-lumps.appspot.com",
  messagingSenderId: "411133748009",
  appId: "1:411133748009:web:69591e56ce1c71604bcd38"
};

export const FIREBASE_CONFIGS = {
  development: stageConfig, // match staging. All dev bits will use emulators
  stage: stageConfig,
  production: productionConfig,
};

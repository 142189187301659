import { Navigate, useLoaderData } from "react-router-dom";
import { completeAuthFlow } from "~/services/notion";

export const CallbackLoaders = {
  notion: notionCallbackLoader,
};

async function notionCallbackLoader({ request }) {
  const url = new URL(request.url);
  const code = url.searchParams.get("code");
  const error = url.searchParams.get("error");
  const state = url.searchParams.get("state");

  if (error) {
    return `/connect?error=${error}`;
  }

  if (!code) {
    return `/connect?error=Notion never responded with code`;
  }

  try {
    await completeAuthFlow({ code, error });

    if (state === "create") {
      return `/list/create`;
    }

    return `/connect?success`;
  } catch (e) {
    return `/connect?error=${e.message || e.toString()}`;
  }
}

export default function CallbackPage() {
  const data = useLoaderData();
  return <Navigate to={data} />;
}

import { useQuery } from "@tanstack/react-query";
import {
  GoogleAuthProvider,
  browserLocalPersistence,
  setPersistence,
  signInWithCustomToken,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
} from "firebase/auth";
import { Auth } from "~/services/firebase";
import * as authService from "~/services/auth";
import _ from "lodash";

const googleAuthProvider = new GoogleAuthProvider();
// TODO: When integrating with Google Calendar, add the following scopes
// googleAuthProvider.addScope("https://www.googleapis.com/auth/calendar.events");
// googleAuthProvider.addScope(
//   "https://www.googleapis.com/auth/calendar.readonly",
// );

const handlePopupLogin = async () => {
  if (import.meta.env.DEV) {
    return await signInWithPopup(Auth, googleAuthProvider);
  } else {
    return await signInWithRedirect(Auth, googleAuthProvider);
  }
};

const handleTokenLogin = async (userId, hash) => {
  const { token } = await authService.authWithHash(userId, hash);

  const result = await signInWithCustomToken(Auth, token);

  if (!result) {
    return result;
  }

  // TODO Verify the accounts permissions allow for calendar access

  return result;
};

const handleRedirectLogin = async () => {
  const redirectAuth = await getRedirectResult(Auth);

  if (redirectAuth?.user) {
    const credential = GoogleAuthProvider.credentialFromResult(redirectAuth);

    return credential;
  }

  return null;
};

export const LOGIN_TOKEN = "token";
export const LOGIN_POPUP = "popup";
export const LOGIN_REDIRECT = "redirect";

export default function useAuthQuery(loginDetails, options = {}) {
  // TODO: Add checks to ensure this is only called from one component at a time

  const { method, userId, hash } = loginDetails || {};

  const {
    data: credential,
    isLoading,
    error: authError,
    refetch: invalidate,
  } = useQuery({
    queryKey: ["googleAuth", method],
    queryFn: async () => {
      await setPersistence(Auth, browserLocalPersistence);

      if (method === LOGIN_REDIRECT) {
        return handleRedirectLogin();
      }

      if (method === LOGIN_POPUP) {
        return handlePopupLogin();
      }

      if (method === LOGIN_TOKEN) {
        return handleTokenLogin(userId, hash);
      }
    },
    enabled:
      method === LOGIN_REDIRECT ||
      method === LOGIN_POPUP ||
      Boolean(method === LOGIN_TOKEN && hash && userId),
    retry: false,
    suspense: false,
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    cacheTime: Infinity,
    staleTime: Infinity,

    ...options,
  });

  return {
    redirectAuthLoading: isLoading,
    tokenAuthLoading: isLoading && method === LOGIN_TOKEN,

    isCredentialLoaded: Boolean(credential),

    authError,
    invalidate,
  };
}

import clsx from "clsx";

export default function Subtitle({ children, className = "", ...props }) {
  return (
    <span
      className={clsx(
        "inline-block text-sm text-gray-800 dark:text-gray-400",
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
}

import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import {
  Badge,
  ListGroup,
  Spinner,
  TextInput,
  ToggleSwitch,
  Tooltip,
} from "flowbite-react";
import React from "react";
import * as notionService from "~/services/notion";
import Subtitle from "./common/Subtitle";
import Icon from "./common/Icon";
import DatabaseIcon from "@/fal/database.svg";
import CheckIcon from "@/far/check.svg";
import SearchIcon from "@/far/search.svg";
import { capitalize } from "lodash";
import ErrorText from "~/components/common/ErrorText";
import NotionIcon from "~/components/common/integration/notion/NotionIcon";

const listGroupItemTheme = {
  link: {
    base: "flex w-full border-b border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-300",
  },
};

const smallSwitchTheme = {
  root: {
    label: "ml-2 text-xs font-medium text-gray-900 dark:text-gray-300",
  },
  toggle: {
    base: "toggle-bg toggle-bg-small h-4 w-7 rounded-full border group-focus:ring-4 group-focus:ring-purple-500/25",
  },
};

function PageListItem({
  notionPage,
  active = false,
  className = "",
  ...props
}) {
  const isDatabasePageItem = notionPage.parent.type === "database_id";
  const title = isDatabasePageItem
    ? notionPage.properties.Title?.title[0]?.plain_text
    : notionPage.properties.title?.title[0]?.plain_text;

  const relativeTimeFormat = new Intl.RelativeTimeFormat(navigator.language, {
    numeric: "auto",
  });

  const editedAgo = capitalize(
    relativeTimeFormat.format(
      -Math.round(
        (Date.now() - new Date(notionPage.last_edited_time).getTime()) /
          1000 /
          60 /
          60 /
          24,
      ),
      "day",
    ),
  );

  // TODO: Suggest a page based on the last edited date and metadata
  return (
    <ListGroup.Item theme={listGroupItemTheme} active={active} {...props}>
      <div
        className={clsx(
          "group/item flex w-full flex-row items-center p-4 text-left",
          active && "bg-purple-500/10",
          className,
        )}
      >
        <div className="flex-1">
          {isDatabasePageItem && (
            <Subtitle className="mb-1 flex items-center">
              <Icon
                Svg={DatabaseIcon}
                className="mr-2 fill-gray-400"
                size={12}
              />
              Database Page
            </Subtitle>
          )}
          <h3 className="mb-1 space-x-2 text-base">
            <NotionIcon icon={notionPage.icon} />
            <span className="font-bold">{title}</span>
          </h3>

          <Subtitle className="flex flex-row space-x-1">
            <span>Last modified &mdash;</span>
            <Tooltip
              content={new Date(notionPage.last_edited_time).toLocaleString()}
            >
              <time dateTime={notionPage.last_edited_time}>{editedAgo}</time>
            </Tooltip>
          </Subtitle>
        </div>

        <div className="flex flex-row space-x-4">
          <Badge
            color="light"
            size="xs"
            // @ts-ignore
            icon={CheckIcon}
            className={clsx(
              active
                ? "bg-purple-500 fill-gray-300 dark:bg-purple-500"
                : "bg-gray-200 fill-transparent dark:bg-gray-700",
            )}
          />
        </div>
      </div>
    </ListGroup.Item>
  );
}

function EmptyList() {
  return (
    <div className="my-8 flex flex-col">
      <span className="text-lg font-bold">No results</span>
      <span className="text-base text-gray-500">
        Try searching for something else
      </span>
    </div>
  );
}

export default function NotionPageSearch({
  value,
  onChange,
  className = "",
  defaultQuery = "",
  ...props
}) {
  const [query, setQuery] = React.useState(defaultQuery);

  const [isShowingDatabases, setIsShowingDatabases] = React.useState(false);

  const isSearchable = query.length >= 2;

  // TODO: Add debounce for when `query` changes?
  const {
    data,
    isLoading, // Is true when fetching for the first time
    isFetching, // Is true when fetching after the first time
    isFetched, // True after the first fetch
    error,
  } = useQuery({
    queryKey: ["notionSearch", query, isShowingDatabases],
    queryFn: async () => {
      let searchQuery = query.trim();
      let isFromUrl = false;

      // By searching for a URL, we can let Notion do the heavy lifting for
      // fetching the page we want. The objective is to lazily "feature" a specific
      // result if it matches the URL we're looking for.
      //
      // As a bonus we get other related pages that might be useful to the user.
      if (URL.canParse(searchQuery)) {
        searchQuery = new URL(searchQuery).pathname.split("/").pop(); // Get the last segment of the URL, name slug included //
        isFromUrl = true;
      }

      const response = await notionService.search(searchQuery);

      const featured = [];
      const results = [...response.results];

      if (isFromUrl) {
        const page = results.find((page) => page.url === query);

        if (page) {
          featured.push(page);
          results.splice(results.indexOf(page), 1);
        }
      }

      return {
        featured,
        results,
        totalCount: featured.length + results.length,
      };
    },
    enabled: isSearchable,
    suspense: false,
    useErrorBoundary: false,
    initialData: { featured: [], results: [], totalCount: 0 },
  });

  const { results, featured, totalCount } = Array.isArray(data)
    ? { results: [], featured: [], totalCount: 0 }
    : data;

  // TODO: Invalidate the query when the search loses focus

  const visibleResults = [
    ...featured,
    ...results.filter(
      (page) =>
        isShowingDatabases ||
        ["page_id", "workspace"].includes(page.parent.type), // Filter out database pages
    ),
  ];

  const Results = () => {
    if (error) {
      return (
        <span className="text-red-500">
          <ErrorText fallback="There were issues searching.">
            Error - {error.toString()}
          </ErrorText>
        </span>
      );
    }

    // Initial state //
    if (isLoading || (!isSearchable && !isFetched)) {
      return null;
    }

    // Actively fetching after first time //
    if (isLoading) {
      // TODO: Use a skeleton loader
      return <Spinner />;
    }

    // Resulting state //
    if (visibleResults?.length) {
      const hiddenCount = totalCount - visibleResults.length;
      return (
        <div>
          <h2 className="mt-4 text-lg">Results</h2>
          <Subtitle className="!mt-0 w-full">
            {visibleResults.length}{" "}
            {visibleResults.length === 1 ? "result" : "results"}{" "}
            {hiddenCount > 0 && `(${hiddenCount} hidden)`}
          </Subtitle>
          <ListGroup className="mt-4 border-gray-300">
            {visibleResults.map((result) => (
              <PageListItem
                key={result.id}
                notionPage={result}
                onClick={() => onChange(result)}
                active={value && value === result.id}
              />
            ))}
          </ListGroup>
        </div>
      );
    } else {
      return <EmptyList />;
    }
  };

  return (
    <div>
      <TextInput
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className={clsx("w-full", className)}
        icon={() => <Icon Svg={SearchIcon} size={16} />}
        placeholder="Search for a page by title or paste a Notion URL"
        rightIcon={() => isFetching && <Spinner />}
        {...props}
      />

      <div className="mt-4 flex flex-row items-center space-x-8">
        <ToggleSwitch
          theme={smallSwitchTheme}
          onChange={setIsShowingDatabases}
          checked={isShowingDatabases}
          label="Show Database Pages"
          color="purple"
        />
      </div>

      <Results />
    </div>
  );
}

import SettingSection from "~/components/settings/SettingSection";
import { useSettingsPageContext } from "~/components/settings/SettingsPageContainer";
import ButtonConfig from "~/components/settings/forms/ButtonConfig";

export default function DangerZonePane() {
  const settingsPageContext = useSettingsPageContext();

  return (
    <SettingSection title="Danger Zone" color="failure">
      <ButtonConfig
        label="Delete"
        onClick={settingsPageContext.deleteAccount}
        title="Delete Account"
        description="This action is not reversible."
        color="failure"
        confirm
      />
    </SettingSection>
  );
}

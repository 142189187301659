import { Button } from "flowbite-react";

import MoonIcon from "@/fas/moon.svg";
import SunIcon from "@/fas/sun.svg";
import Icon from "~/components/common/Icon";
import { useDarkMode } from "~/hooks/useDarkMode";

const theme = {
  color: {
    light:
      "bg-transparent text-gray-900 dark:bg-transparent dark:text-gray-300",
  },
  size: {
    md: "text-sm p-1.5",
  },
};

export default function DarkModeToggle() {
  const { isDarkMode, toggle } = useDarkMode();

  const ModeIcon = isDarkMode ? SunIcon : MoonIcon;

  return (
    <Button size="md" color="light" onClick={toggle} theme={theme}>
      <Icon
        Svg={ModeIcon}
        size={16}
        className="fill-black dark:fill-gray-300"
      />
    </Button>
  );
}

// Taken from https://usehooks-ts.com/react-hook/use-window-size
// With some modifications related to initial state and dependencies

import React from "react";

import { useEventListener, useIsomorphicLayoutEffect } from "usehooks-ts";

export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleSize = React.useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEventListener("resize", handleSize);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, [handleSize]);

  return windowSize;
}

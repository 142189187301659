import { Button } from "flowbite-react";
import React from "react";
import { useOnClickOutside } from "usehooks-ts";

export default function ButtonConfig({
  onClick = null,
  label,
  title,
  description,
  color = "gray",
  confirm = false,
  buttonProps = {},
  ...props
}) {
  const [isConfirming, setIsConfirming] = React.useState(false);

  const buttonRef = React.useRef(null);
  useOnClickOutside(buttonRef, () => setIsConfirming(false));

  const handleClick = () => {
    if (confirm) {
      if (isConfirming) {
        onClick();
      } else {
        setIsConfirming(true);
      }
    } else {
      onClick();
    }
  };

  return (
    <div className="flex items-center justify-between" {...props}>
      <div className="flex flex-col">
        <h1 className="text-md font-semibold">{title}</h1>
        <p>{description}</p>
      </div>
      <Button
        onClick={onClick ? handleClick : undefined}
        color={color}
        ref={buttonRef}
        {...buttonProps}
      >
        {isConfirming ? "Confirm?" : label}
      </Button>
    </div>
  );
}

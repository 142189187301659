import { Alert, Button, Timeline } from "flowbite-react";
import React from "react";
import { useAuthContext } from "~/containers/AuthContainer";
import { useProjectContext } from "~/containers/ProjectContainer";

import Page from "~/components/Page";
import Icon from "~/components/common/Icon";
import Subtitle from "~/components/common/Subtitle";
import FlatCard from "~/components/common/FlatCard";
import Container from "~/components/common/Container";
import NotionPageSearch from "~/components/NotionPageSearch";

import SpinnerThirdIcon from "@/far/spinner-third.svg";
import InfoCircleIcon from "@/fas/info-circle.svg";
import WarningIcon from "@/fas/exclamation-triangle.svg";
import SparkleIcon from "@/fas/sparkles.svg";
import ArrowRight from "@/far/arrow-right.svg";
import { useLocation } from "react-router-dom";
import {
  CreateTaskListPageProvider,
  useCreateTaskListPageContext,
} from "~/components/create-task-list/CreateTaskListPageContainer";
import DatabaseEditor from "~/components/create-task-list/DatabaseEditor";
import DatabaseEditorLoading from "~/components/create-task-list/DatabaseEditorLoading";
import LinkButton from "~/components/common/LinkButton";
import HowToDuplicatePageImage from "~/assets/img/how-to-duplicate.png";

const NOTION_AUTH_URL = import.meta.env.VITE_NOTION_AUTHORIZATION_URL;

function Step1() {
  return (
    <FlatCard className="mt-4">
      <div className="space-y-8">
        <h2 className="text-lg">Create page in Notion</h2>

        <Timeline>
          <Timeline.Item>
            <Timeline.Point />
            <Timeline.Content>
              <Timeline.Time>Step 1</Timeline.Time>
              <Timeline.Title>Pick a template</Timeline.Title>
              <Timeline.Body>
                <div className="pt-4">
                  <Button
                    color="light"
                    fullSized={false}
                    href={import.meta.env.VITE_NOTION_TEMPLATES_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline"
                  >
                    <p>Create from template</p>
                    <Icon Svg={ArrowRight} className="ml-2" />
                  </Button>
                </div>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <Timeline.Point />
            <Timeline.Content>
              <Timeline.Time>Step 2</Timeline.Time>
              <Timeline.Title>Duplicate template</Timeline.Title>
              <Timeline.Body>
                <div className="pt-4">
                  <img
                    src={HowToDuplicatePageImage}
                    alt="How to duplicate page"
                    className="w-full rounded-md shadow"
                  />
                </div>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
        </Timeline>
      </div>
    </FlatCard>
  );
}

function Step2() {
  const { pageQuery, selectedPageId, setSelectedPageId } =
    useCreateTaskListPageContext();

  const { taskLists = {} } = useProjectContext();

  const duplicatePages = React.useMemo(
    () =>
      Object.entries(taskLists)
        .filter(([_, taskList]) => taskList.notionPageId === selectedPageId)
        .map(([id, taskList]) => ({ id, ...taskList })),
    [taskLists, selectedPageId],
  );

  const defaultSearchQuery =
    // @ts-ignore
    pageQuery.data?.properties?.title?.title[0]?.plain_text;

  return (
    <FlatCard className="mt-4">
      <div className="space-y-8">
        <h2 className="text-lg">
          Link page <span className="text-red-400">*</span>
        </h2>

        <NotionPageSearch
          value={selectedPageId}
          defaultQuery={defaultSearchQuery}
          onChange={(page) => setSelectedPageId(page.id)}
        />

        <Subtitle className="flex items-center space-x-3">
          <Icon
            Svg={InfoCircleIcon}
            className="fill-gray-800 dark:fill-gray-400"
            size={14}
          />
          <span>Not seeing the correct pages?</span>
          <LinkButton href={`${NOTION_AUTH_URL}&state=create`} external>
            Update selection
          </LinkButton>
        </Subtitle>

        {duplicatePages.length > 0 && (
          <Alert color="warning" className="mt-4">
            <Icon
              Svg={WarningIcon}
              className="mr-3 fill-yellow-900"
              size={14}
            />
            This page is already linked to a task list(s):
            {duplicatePages.map((page, index) => (
              <span key={page.id}>
                {index ? ", " : " "}
                <LinkButton color="yellow" href={`/list/${page.id}`} newTab>
                  {page.name}
                </LinkButton>
              </span>
            ))}
          </Alert>
        )}
      </div>
    </FlatCard>
  );
}

function Step3() {
  const { selectedPageId, pageQuery, databaseQuery } =
    useCreateTaskListPageContext();

  const { taskLists = {} } = useProjectContext();

  const selectedDatabaseId = databaseQuery.data?.id;
  const duplicateDatabases = React.useMemo(
    () =>
      Object.entries(taskLists)
        .filter(
          ([_, taskList]) => taskList.notionDatabaseId === selectedDatabaseId,
        )
        .map(([id, taskList]) => ({ id, ...taskList })),
    [taskLists, selectedDatabaseId],
  );

  const EditorContent = React.useMemo(
    () =>
      function EditorContent() {
        if (!selectedPageId) {
          return (
            <Alert color="neutral" className="mb-4">
              Must select a page first
            </Alert>
          );
        }

        if (pageQuery.isLoading || databaseQuery.isLoading) {
          return <DatabaseEditorLoading />;
        }

        return <DatabaseEditor />;
      },
    [databaseQuery.isLoading, pageQuery.isLoading, selectedPageId],
  );

  return (
    <FlatCard>
      <div className="space-y-8">
        <h2 className="text-lg">
          Select Database <span className="text-red-400">*</span>
        </h2>

        {duplicateDatabases.length > 0 && (
          <Alert color="warning">
            <Icon
              Svg={WarningIcon}
              className="mr-3 fill-yellow-900"
              size={14}
            />
            This database is already linked to a task list(s):
            {duplicateDatabases.map((page, index) => (
              <span key={page.id}>
                {index ? ", " : " "}
                <LinkButton
                  color="yellow"
                  href={`/list/${page.id}`}
                  newTab // while not technically external, we want to open in a new tab
                >
                  {page.name}
                </LinkButton>
              </span>
            ))}
          </Alert>
        )}

        <EditorContent />
      </div>
    </FlatCard>
  );
}

function Content() {
  const { state } = useLocation();
  const { hideCreateTemplateStep = false } = state || {};

  const {
    canCreate,
    selectedPageId,
    createTaskListMutation,
    loadingStep,
    isCreatingFromAutoCreate,
    verificationError,
  } = useCreateTaskListPageContext();

  if (isCreatingFromAutoCreate && selectedPageId) {
    return (
      <FlatCard>
        <div className="flex flex-col items-center justify-center space-y-16 py-16">
          <div className="text-center">
            <span className="relative flex inline-flex h-[64px] w-[64px]">
              <Icon
                Svg={SparkleIcon}
                className="absolute mb-4 inline-flex animate-ping fill-yellow-300 opacity-75 dark:fill-yellow-300"
                size={64}
              />
              <Icon
                Svg={SparkleIcon}
                className="relative mb-4 inline-flex fill-purple-500 dark:fill-purple-500"
                size={64}
              />
            </span>
          </div>
          <span className="mb-4 text-xl">Creating...</span>
        </div>
      </FlatCard>
    );
  }

  // TODO: Wrap in Suspense show loading states
  // TODO: Add error handling
  return (
    <>
      {!hideCreateTemplateStep && <Step1 />}
      <Step2 />
      <Step3 />
      <div className="flex justify-end space-x-4">
        {createTaskListMutation.isError && (
          <span className="text-red-500">
            Error: {createTaskListMutation.error?.message}
          </span>
        )}
        {verificationError && (
          <span className="text-red-500">
            Error: {verificationError}{" "}
            <LinkButton color="red" href="/list">
              Go to task lists.
            </LinkButton>
          </span>
        )}
        <Button
          color="purple"
          onClick={() => createTaskListMutation.mutateAsync()}
          isProcessing={Boolean(loadingStep)}
          processingSpinner={
            <Icon
              Svg={SpinnerThirdIcon}
              className="animate-spin fill-gray-300"
              size={25}
            />
          }
          disabled={!canCreate}
          size="xl"
        >
          {loadingStep || "Create"}
          <Icon Svg={SparkleIcon} className="ml-3" size={18} />
        </Button>
      </div>
    </>
  );
}

export default function CreateTaskListPage() {
  const { googleUser } = useAuthContext();
  const { state } = useLocation();

  const { defaultSelectedPageId = null, autoCreate = false } = state || {};

  const { displayName = "Frankie" } = googleUser;
  const defaultTitle = `${displayName}'s Task List`;

  return (
    <CreateTaskListPageProvider
      defaultSelectedPageId={defaultSelectedPageId}
      defaultTitle={defaultTitle}
      autoCreate={autoCreate}
    >
      <Page>
        <Container title="Create Task List" className="py-16">
          <Content />
        </Container>
      </Page>
    </CreateTaskListPageProvider>
  );
}

import React from "react";

import { capitalize } from "lodash";
import PulseStatusIndicator from "~/components/common/PulseStatusIndicator";
import HelpTip from "~/components/common/HelpTip";
import useNotion from "~/hooks/useNotion";
import Subtitle from "~/components/common/Subtitle";
import SyncIcon from "@/far/sync.svg";
import Icon from "~/components/common/Icon";
import clsx from "clsx";

const UPDATE_LAST_SYNCED_INTERVAL = 1000 * 60; // Once per minute //

function Wrapper({ children }) {
  return (
    <div className="flex w-full flex-row items-center justify-between text-sm">
      {children}
    </div>
  );
}

export default function NotionPageStatus({ notionPageId }) {
  const { pageQuery } = useNotion(notionPageId);
  const { data, isLoading, isFetching, error, dataUpdatedAt } = pageQuery || {};

  const [renderKey, setRenderKey] = React.useState(0);

  React.useEffect(() => {
    let interval;

    if (data && dataUpdatedAt > 0) {
      interval = setInterval(() => {
        setRenderKey((prevKey) => prevKey + 1);
      }, UPDATE_LAST_SYNCED_INTERVAL);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [data, dataUpdatedAt, pageQuery]);

  if (!pageQuery) {
    return null;
  }

  if (isLoading) {
    return (
      <Wrapper>
        <PulseStatusIndicator color="primary" loading className="mr-2" />
        Fetching updates...
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <span className="flex items-center">
          <PulseStatusIndicator color="red" className="mr-2" />
          Error fetching status
          <HelpTip className="ml-2">
            There was an error attempting to load the status. Refresh and try
            again.
          </HelpTip>
        </span>
      </Wrapper>
    );
  }

  if (!data || data.archived || !dataUpdatedAt) {
    return (
      <Wrapper>
        <span className="flex items-center">
          <PulseStatusIndicator color="yellow" className="mr-2" />
          Page Unlinked
          <HelpTip className="ml-2">
            The Notion page associated with this task list was deleted.
          </HelpTip>
        </span>
      </Wrapper>
    );
  }

  const relativeTimeFormat = new Intl.RelativeTimeFormat(navigator.language, {
    numeric: "auto",
  });

  const updatedAgo = capitalize(
    relativeTimeFormat.format(
      -Math.round((Date.now() - dataUpdatedAt) / 1000 / 60),
      "minute",
    ),
  );

  return (
    <div className="w-full space-y-3">
      <span className="text-lg font-bold">
        {
          // @ts-ignore
          data?.properties?.title?.title[0]?.text?.content
        }
      </span>
      <Wrapper key={renderKey}>
        <span className="flex items-center">
          <PulseStatusIndicator color="green" className="mr-2" />
          Connected
        </span>
        <Subtitle>
          <div
            className="inline cursor-pointer"
            onClick={() => pageQuery.refetch()}
          >
            <Icon
              Svg={SyncIcon}
              size={10}
              className={clsx(
                "mr-2 fill-gray-800 dark:fill-gray-400",
                isFetching && "animate-spin",
              )}
            />
          </div>
          {updatedAgo}
        </Subtitle>
      </Wrapper>
    </div>
  );
}

// Taken from https://usehooks-ts.com/react-hook/use-dark-mode
// With the ability to apply a scope to the dark mode. Allowing for
// dark mode to be applied to a specific element/page, instead of the whole
// app.
// Useful for embeds that default to light mode, but can be toggled to dark mode.

import { useLocalStorage, useMediaQuery, useUpdateEffect } from "usehooks-ts";
import { useAppContext } from "~/containers/AppContainer";

const COLOR_SCHEME_QUERY = "(prefers-color-scheme: dark)";

export function useDarkMode() {
  const { darkModeScope } = useAppContext();
  const isDarkOS = useMediaQuery(COLOR_SCHEME_QUERY);

  const [isDarkMode, setDarkMode] = useLocalStorage(
    `usehooks-ts-dark-mode-${darkModeScope}`,
    isDarkOS ?? false,
  );

  // Update darkMode if os prefers changes
  useUpdateEffect(() => {
    setDarkMode(isDarkOS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkOS]);

  return {
    isDarkMode,
    toggle: () => setDarkMode((prev) => !prev),
    enable: () => setDarkMode(true),
    disable: () => setDarkMode(false),
  };
}

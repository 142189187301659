export const NOTION_SELECT_COLORS = {
  default:
    "bg-gray-100 text-gray-800 dark:bg-gray-300/20 dark:text-gray-400 border-none",
  blue: "bg-blue-300 text-blue-800 dark:bg-blue-500/20 dark:text-blue-300 border-none",
  brown:
    "bg-amber-100 text-amber-800 dark:bg-amber-300/20 dark:text-amber-300 border-none",
  gray: "bg-gray-100 text-gray-800 dark:bg-gray-300/10 dark:text-gray-300 border-none",
  green:
    "bg-green-300 text-green-800 dark:bg-green-500/20 dark:text-green-300 border-none",
  orange:
    "bg-orange-300 text-orange-800 dark:bg-orange-400/20 dark:text-orange-300 border-none",
  pink: "bg-pink-300 text-pink-800 dark:bg-pink-500/20 dark:text-pink-300 border-none",
  purple:
    "bg-purple-300 text-purple-800 dark:bg-purple-500/20 dark:text-purple-300 border-none",
  red: "bg-red-300 text-red-800 dark:bg-red-500/20 dark:text-red-300 border-none",
  yellow:
    "bg-yellow-300 text-yellow-800 dark:bg-yellow-500/20 dark:text-yellow-300 border-none",
};
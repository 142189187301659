import { useMutation } from "@tanstack/react-query";
import React from "react";

/**
 * For use with react-query, this hook prevents a mutation from
 * being fired twice due to React.StrictMode.
 *
 * Shouldn't the callbacks be idempotent? Yes, but sometimes they're not.
 * So this is for those times.
 *
 * @param {*} options
 * @returns
 */
export function useAntiStrictMutation(options) {
  const firedRef = React.useRef(false);

  return useMutation({
    mutationFn: async (...args) => {
      if (firedRef.current && React.StrictMode) {
        console.log("Mutation already fired, skipping...");
        return;
      }

      firedRef.current = true;
      const result = await options.mutationFn(...args);
      firedRef.current = false;

      return result;
    },
  });
}

import { Suspense } from "react";
import { ErrorBoundary as RollbarErrorBoundary } from "@rollbar/react";

import EmbedLoading from "~/components/embed/EmbedLoading";
import EmbedError from "~/components/embed/EmbedError";
import Embed from "~/components/embed/Embed";
import { useSearchParams } from "react-router-dom";
import { AuthProvider } from "~/containers/AuthContainer";
import EmbedUnauthenticated from "~/components/embed/EmbedUnauthenticated";
import { ProjectProvider } from "~/containers/ProjectContainer";
import { TaskProvider } from "~/containers/TaskContainer";
import Page from "~/components/Page";
import { EmbedProvider } from "~/containers/EmbedContainer";
import { useDarkModeScope } from "~/containers/AppContainer";
import useAuthQuery from "~/hooks/useAuthQuery";
import LayoutFooter from "~/components/layout/LayoutFooter";

/*
 * See the cloudflare worker(s) for embed page modifications.
 * Modifications include:
 * - Injecting iFramely meta tags
 * - Removing X-Frame-Options header (this is an embed after all)
 */

function Content() {
  const [searchParams] = useSearchParams();

  const taskListId = searchParams.get("listId");
  const userId = searchParams.get("userId");
  const hash = searchParams.get("hash");

  useAuthQuery(
    { method: "token", userId, hash },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

  if (!taskListId || !userId || !hash) {
    return <EmbedUnauthenticated />;
  }

  return (
    <AuthProvider>
      <ProjectProvider>
        <TaskProvider taskListId={taskListId}>
          <EmbedProvider>
            <Embed />
          </EmbedProvider>
        </TaskProvider>
      </ProjectProvider>
    </AuthProvider>
  );
}

export default function EmbedPage() {
  useDarkModeScope("embed");

  return (
    <>
      <Page
        className="relative h-screen min-h-[450px] overflow-hidden border border-gray-300 dark:border-gray-800"
        hideNavigation
        hideFooter // Embed has its own footer
      >
        <div className="group/embed h-full p-1">
          <Suspense fallback={<EmbedLoading />}>
            <RollbarErrorBoundary
              fallbackUI={(error) => <EmbedError error={error} />}
            >
              <Content />
            </RollbarErrorBoundary>
          </Suspense>
        </div>

        <div className="absolute bottom-2 w-full px-4">
          <LayoutFooter />
        </div>
      </Page>
    </>
  );
}

import TaskListItemLoading from "../task-list/TaskListItemLoading";
import BannerLoading from "./BannerLoading";

export default function SingleTaskListLoading() {
  return (
    <div className="space-y-8 px-16">
      <BannerLoading />
      <TaskListItemLoading />
    </div>
  );
}

import clsx from "clsx";

export default function LoadingBlock({
  width = "w-full",
  height = "h-full",
  className = "",
  ...props
}) {
  return (
    <div
      className={clsx(
        width,
        height,
        "animate-pulse rounded-md bg-gray-100 dark:bg-gray-800",
        className,
      )}
      {...props}
    />
  );
}

import { Suspense } from "react";
import { useParams } from "react-router-dom";
import SingleTaskList from "~/components/single-task-list/SingleTaskList";
import SingleTaskListLoading from "~/components/single-task-list/SingleTaskListLoading";

export default function SingleTaskListPage() {
  const { listId } = useParams();

  if (!listId) {
    throw new Error("Missing listId");
  }

  return (
    <Suspense fallback={<SingleTaskListLoading />}>
      <SingleTaskList taskListId={listId} />
    </Suspense>
  );
}

import LoadingBlock from "~/components/common/LoadingBlock";

export default function DatabaseEditorLoading() {
  return (
    <div className="space-y-8">
      <LoadingBlock width="w-full" height="h-16" />
      <LoadingBlock width="w-full" height="h-64" />
    </div>
  );
}

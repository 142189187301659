import React from "react";
import { useAuthContext } from "~/containers/AuthContainer";
import useNotion from "~/hooks/useNotion";
import * as firebaseService from "~/services/firebase";
import * as rtdb from "firebase/database";
import useManagedCallback from "~/hooks/useManagedCallback";

const ConnectPageContext = React.createContext({
  /**
   * @type {import("@tanstack/react-query").UseQueryResult<import("@notionhq/client/build/src/api-endpoints").PageObjectResponse, unknown>}
   */
  pageQuery: null,

  /**
   * @type {import("@tanstack/react-query").UseQueryResult<import("@notionhq/client/build/src/api-endpoints").DatabaseObjectResponse, unknown>}
   */
  databaseQuery: null,

  clearNotionCredentials: () => {},
});

export function useConnectPageContext() {
  const context = React.useContext(ConnectPageContext);

  if (context === undefined) {
    throw new Error(
      "useConnectPageContext must be used within an ConnectPageProvider",
    );
  }

  return context;
}

export const ConnectPageProvider = ({ children }) => {
  const { loggedInUser } = useAuthContext();
  const { notion } = loggedInUser || {};

  const defaultSelectedPageId = notion?.duplicatedTemplateId;

  const notionQueries = useNotion(defaultSelectedPageId, {
    retry: 10,
    retryDelay: 2000,
  });

  const clearNotionCredentials = useManagedCallback(async () => {
    if (!loggedInUser) return;

    await rtdb.remove(firebaseService.UsersRef("notion"));

    localStorage.removeItem("notionAccessToken");
    location.reload();
  }, [loggedInUser]);

  return (
    <ConnectPageContext.Provider
      value={{ ...notionQueries, clearNotionCredentials }}
    >
      {children}
    </ConnectPageContext.Provider>
  );
};

import Subtitle from "~/components/common/Subtitle";
import { useEmbedContext } from "~/containers/EmbedContainer";

const suggestions = [
  {
    title: "Go to the gym",
    description: "for 30 minutes after work",
  },
  {
    title: "Do the laundry",
    description: "after 5pm tomorrow",
  },
  {
    title: "Read the next chapter",
    description: "for book club",
  },
  {
    title: "Clear out email inbox",
    description: "for 30 minutes before work",
  },
];

export default function TaskSuggestion() {
  const { setInputValue } = useEmbedContext();

  return (
    <div className="h-[150px] pt-2">
      <div className="grid grid-cols-2 gap-2">
        {suggestions.map((suggestion) => (
          <button
            key={suggestion.title}
            className="flex flex-col gap-1 rounded-md border border-gray-300 bg-white p-3 text-left shadow-sm transition-colors hover:border-purple-500 dark:border-gray-200/10 dark:bg-gray-825 dark:hover:bg-gray-800"
            onClick={() =>
              setInputValue(`${suggestion.title} ${suggestion.description}`)
            }
          >
            <h4 className="mb-0 text-sm font-bold leading-none">
              {suggestion.title}
            </h4>
            <Subtitle className="text-sm opacity-70">
              {suggestion.description}
            </Subtitle>
          </button>
        ))}
      </div>
    </div>
  );
}

import { Suspense } from "react";
import Page from "~/components/Page";
import Container from "~/components/common/Container";
import { SettingsPageProvider } from "~/components/settings/SettingsPageContainer";
import AccountDetailsPane from "~/components/settings/pane/AccountDetailsPane";
import DangerZonePane from "~/components/settings/pane/DangerZonePane";
import IntegrationsPane from "~/components/settings/pane/IntegrationPane";

function SettingsLoading() {
  return <div>Loading...</div>;
}

function Settings() {
  return (
    <Page>
      <Container title="Settings" className="py-16">
        <div className="space-y-16">
          <AccountDetailsPane />
          {/* <SecurityPane /> */}
          <IntegrationsPane />
          <DangerZonePane />
        </div>
      </Container>
    </Page>
  );
}

export default function SettingsPage() {
  return (
    <Suspense fallback={<SettingsLoading />}>
      <SettingsPageProvider>
        <Settings />
      </SettingsPageProvider>
    </Suspense>
  );
}

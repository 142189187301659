import clsx from "clsx";
import Title from "./Title";

export default function Container({
  children,
  title = "",
  actions = null,
  compact = false,
  className = "",
  ...props
}) {
  return (
    <div
      className={clsx(
        "mx-auto w-full max-w-7xl space-y-8",
        {
          "lg:w-2/3": compact,
        },
        compact ? "px-4 md:px-6 lg:px-8" : "px-4 md:px-8 lg:px-16",
        className,
      )}
      {...props}
    >
      {(title || actions) && (
        <div className="mb-12 flex w-full flex-row items-center justify-between">
          {title && <Title className="mb-0">{title}</Title>}
          {actions && <div>{actions()}</div>}
        </div>
      )}

      {children}
    </div>
  );
}

/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Auth, UsersRef, setCurrentUser } from "~/services/firebase";
import { useQuery } from "@tanstack/react-query";
import { useDatabasesQuery } from "~/hooks/useDatabasesQuery";

const AuthContext = React.createContext({
  googleUser: null,
  loggedInUser: null,
  isNotionAuthenticated: false,
});

export function useAuthContext() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }

  return context;
}

export async function logout() {
  await Auth.signOut();

  localStorage.clear();

  window.location.href = "/login";
}

export function AuthProvider({ children }) {
  const {
    data: googleUser,
    error: googleError,
    isLoading: googleUserLoading,
  } = useQuery({
    queryKey: ["googleAuth"],
    queryFn: async () => {
      const bailOut = () => {
        logout();
        return null;
      };

      try {
        await Auth.authStateReady();

        const user = Auth.currentUser;

        if (!user) {
          return bailOut();
        }

        return user;
      } catch (error) {
        return bailOut();
      }
    },
  });

  // Expected to be called multiple times. Will overwrite the current user if different. //
  setCurrentUser(googleUser);

  const userId = googleUser?.uid;

  const loggedInUserRefs = React.useMemo(
    () => (userId ? [UsersRef()] : []),
    [userId],
  );

  const { data: loggedInUserResult, isLoading: loggedInUserLoading } =
    useDatabasesQuery({
      queryKey: ["user"],
      databaseRefs: loggedInUserRefs,
      options: {
        enabled: !googleUserLoading && Boolean(googleUser),
        refetchOnWindowFocus: true,
      },
    });

  const loggedInUser = loggedInUserResult?.[userId];
  const isNotionAuthenticated = Boolean(loggedInUser?.notion?.accessToken);

  React.useEffect(() => {
    localStorage.setItem(
      "notionAccessToken",
      loggedInUser?.notion?.accessToken,
    );
  }, [loggedInUser?.notion?.accessToken]);

  React.useEffect(() => {
    const shouldRedirectToConnectPage =
      !googleUserLoading && Boolean(googleUser) && !isNotionAuthenticated;

    if (
      shouldRedirectToConnectPage &&
      window.location.pathname !== "/connect"
    ) {
      window.location.href = "/connect";
    }
  }, [googleUserLoading, googleUser, isNotionAuthenticated]);

  if (googleError) {
    throw googleError;
  }

  if (googleUserLoading || loggedInUserLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        googleUser,
        loggedInUser,

        isNotionAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

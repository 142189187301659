import clsx from "clsx";

const colors = {
  default: "border-gray-300 dark:border-gray-600",
  purple: "border-purple-500 dark:border-purple-500",
  blue: "border-blue-500 dark:border-blue-500",
  failure: "border-red-500 dark:border-red-500",
  warning: "border-yellow-500 dark:border-yellow-500",
  success: "border-green-500 dark:border-green-500",
};

export default function ListGroup({
  color = "default",
  children,
  className = "",
  ...props
}) {
  return (
    <ul
      className={clsx(
        "w-full rounded-lg border bg-white text-gray-900 shadow dark:bg-gray-900 dark:text-gray-300",
        colors[color || "default"],
        className,
      )}
      {...props}
    >
      {children}
    </ul>
  );
}

ListGroup.Item = function ListGroupItem({
  children,
  className = "",
  ...props
}) {
  return (
    <li
      className={clsx(
        "w-full rounded-t-lg border-b border-gray-300 px-6 py-4 last:border-none dark:border-gray-600",
        className,
      )}
      {...props}
    >
      {children}
    </li>
  );
};

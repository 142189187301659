import React from "react";

/**
 * @returns {any|undefined}
 */
export default function usePrevious(value) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

import Icon from "~/components/common/Icon";
import Template from "~/assets/img/template.svg";
import PickAPage from "~/assets/img/pick-a-page.svg";
import { useNavigate } from "react-router-dom";
import Subtitle from "../common/Subtitle";
import IconButton from "../common/IconButton";
import FlatCard from "../common/FlatCard";

export default function EmptyTaskList() {
  const navigate = useNavigate();

  return (
    <FlatCard>
      <div className="mb-16 flex flex-col items-center justify-center space-y-4">
        <span className="text-2xl font-bold">You have no task lists</span>
        <span className="text-lg text-gray-500">
          Create a task to get started
        </span>
      </div>
      <div className="flex w-full flex-col justify-around space-y-8 md:flex-row md:space-x-4 md:space-y-0">
        <div className="basis-1/2">
          <IconButton
            className="w-full"
            onClick={() => navigate("/list/create")}
          >
            <div className="align-center flex w-full flex-col items-center justify-center space-y-8 p-4">
              <Icon
                Svg={Template}
                className="fill-purple-500 dark:fill-purple-500"
                size={80}
              />
              <span className="text-lg">Create from template</span>
            </div>
          </IconButton>
          <Subtitle className="mt-2 w-full text-center">
            Recommended for beginners
          </Subtitle>
        </div>

        <div className="basis-1/2">
          <IconButton
            className="w-full"
            onClick={() =>
              navigate("/list/create", {
                state: { hideCreateTemplateStep: true },
              })
            }
          >
            <div className="align-center flex w-full flex-col items-center justify-center space-y-8 p-4">
              <Icon
                Svg={PickAPage}
                className="fill-purple-500 dark:fill-purple-500"
                size={80}
              />
              <span className="text-lg">Link existing page</span>
            </div>
          </IconButton>
        </div>
      </div>
    </FlatCard>
  );
}

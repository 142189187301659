import clsx from "clsx";
import { truncate } from "lodash";

const defaultVersion = `${
  import.meta.env.DEV
    ? import.meta.env.VITE_VERSION
    : truncate(import.meta.env.VITE_VERSION, { length: 9 })
}@${import.meta.env.VITE_DISPLAY_ENV}`;

export default function Version({ version = null, className = "", ...props }) {
  return (
    <span className={clsx("text-xs text-gray-400", className)} {...props}>
      Version {version || defaultVersion}
    </span>
  );
}

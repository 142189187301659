import { Avatar, Badge, Sidebar } from "flowbite-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext, logout } from "~/containers/AuthContainer";

import ClipboardListCheckIcon from "@/far/clipboard-list-check.svg";
import BugIcon from "@/far/bug.svg";
import LightBulbIcon from "@/far/lightbulb.svg";
import PencilRulerIcon from "@/far/pencil-ruler.svg";
import Icon from "../common/Icon";
import React, { memo } from "react";
import { useAppContext } from "~/containers/AppContainer";
import usePrevious from "~/hooks/usePrevious";
import { Transition } from "@headlessui/react";
import TimesIcon from "@/fal/times.svg";
import DefaultAvatar from "~/assets/img/default-avatar.svg";
import CloseButton from "~/components/common/CloseButton";
import { useLocalStorage } from "usehooks-ts";

const theme = {
  root: {
    collapsed: {
      off: "w-full sm:w-64",
    },
  },
  item: {
    collapsed: {
      insideCollapse: "group w-full pl-5 transition duration-75",
    },
  },
  itemGroup:
    "mt-4 space-y-2 border-t border-gray-300 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700",
};

const sidebarLinks = [
  {
    items: [
      {
        label: "Task Lists",
        to: "/list",
        icon: ClipboardListCheckIcon,
        isActiveRelative: true,
      },
      {
        label: "Templates",
        to: import.meta.env.VITE_NOTION_TEMPLATES_URL,
        icon: PencilRulerIcon,
        isExternal: true,
      },
    ],
  },
  {
    items: [
      {
        label: "Report a bug",
        to: "https://github.com/headdetect/frankie-ai-docs/discussions/categories/bugs-issues",
        icon: BugIcon,
        isExternal: true,
      },
      {
        label: "Request a feature",
        to: "https://github.com/headdetect/frankie-ai-docs/discussions/categories/ideas",
        icon: LightBulbIcon,
        isExternal: true,
      },
    ],
  },
];

function ItemLink({ item, children, ...props }) {
  let Parent = Sidebar.Item;

  if (item.children) {
    // @ts-ignore
    Parent = Sidebar.Collapse;
  }

  return (
    <Parent
      to={item.to}
      as={Link}
      icon={() => <Icon Svg={item.icon} size={16} />}
      rel={item.to.startsWith("http") ? "noopener noreferrer" : undefined}
      target={item.to.startsWith("http") ? "_blank" : undefined}
      {...props}
      className="flex flex-row items-center justify-between"
      label={item.children && item.label}
    >
      {item.children && !item.isExternal
        ? item.children.map((child) => (
            <Sidebar.Item
              key={child.label}
              to={child.to}
              as={Link}
              icon={() => child.icon && <Icon Svg={child.icon} />}
              rel={
                child.to.startsWith("http") ? "noopener noreferrer" : undefined
              }
              target={child.to.startsWith("http") ? "_blank" : undefined}
              className="flex flex-row items-center justify-between"
            >
              {child.label}
            </Sidebar.Item>
          ))
        : children}
    </Parent>
  );
}

export default function LayoutSidebar() {
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const [isBetaNoticeVisible, setBetaNoticeVisible] = useLocalStorage(
    "notice-beta",
    true,
  );

  const { isMobile, isSidebarContentVisible, isSidebarOpen, setIsSidebarOpen } =
    useAppContext();

  React.useEffect(() => {
    if (previousLocation && previousLocation.key !== location.key) {
      setIsSidebarOpen(false);
    }
  }, [location, previousLocation, setIsSidebarOpen]);

  function active(path, exactMatch = true) {
    return exactMatch
      ? location.pathname === path
      : location.pathname.startsWith(path);
  }

  return (
    <Transition
      as={React.Fragment}
      show={isSidebarOpen || !isMobile}
      enter="transform transition ease-in-out duration-300 sm:duration-500"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-in-out duration-300 sm:duration-500"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full"
    >
      <div className="fixed top-0 z-10 h-full max-h-screen w-full sm:sticky sm:h-screen sm:w-auto">
        <Sidebar theme={theme} aria-label="Side navigation">
          <div className="flex h-full flex-col justify-between">
            <div>
              <div className="flex flex-row justify-between">
                <Sidebar.Logo href="/" img="/logo.svg" imgAlt="FrankieAI logo">
                  <p>FrankieAI</p>
                </Sidebar.Logo>
                <div className="visible sm:hidden">
                  <button
                    className="mr-1 flex items-center justify-center"
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Icon Svg={TimesIcon} size={20} />
                  </button>
                </div>
              </div>

              <Sidebar.Items>
                <Sidebar.ItemGroup>
                  <UserSidebarItem />
                </Sidebar.ItemGroup>
                {isSidebarContentVisible &&
                  sidebarLinks.map((group, index) => (
                    <Sidebar.ItemGroup key={index}>
                      {group.items.map((item, index) => (
                        <ItemLink
                          item={item}
                          active={
                            active(item.to, !item.isActiveRelative) || undefined
                          }
                          key={index}
                        >
                          <p>{item.label}</p>
                        </ItemLink>
                      ))}
                    </Sidebar.ItemGroup>
                  ))}
              </Sidebar.Items>
            </div>

            {isBetaNoticeVisible && (
              <div>
                <Sidebar.CTA className="mb-3">
                  <div className="mb-3 flex items-center justify-between">
                    <Badge color="info">Beta</Badge>
                    <CloseButton onClose={() => setBetaNoticeVisible(false)} />
                  </div>
                  <div className="mb-3 text-xs text-cyan-900 dark:text-gray-400">
                    <p className="mb-5">
                      We are currently in our beta phase, refining features and
                      ironing out the bugs.
                    </p>
                    <p>
                      If you have any feedback, please submit it{" "}
                      <a href="/feedback" className="text-sky-600">
                        here
                      </a>
                      .
                    </p>
                  </div>
                </Sidebar.CTA>
              </div>
            )}
          </div>
        </Sidebar>
      </div>
    </Transition>
  );
}

function UserSidebarItem() {
  let auth = useAuthContext();
  let navigate = useNavigate();

  if (!auth.googleUser) return null;

  const avatarImage = auth.googleUser.photoURL || DefaultAvatar;

  return (
    <Sidebar.Collapse
      icon={() => (
        // @ts-ignore
        <MemoizedAvatar img={avatarImage} />
      )}
      label={auth.googleUser.displayName}
    >
      <Sidebar.Item to="/settings" as={Link}>
        Settings
      </Sidebar.Item>
      <Sidebar.Item
        className="cursor-pointer"
        onClick={() => {
          logout();
          navigate("/login");
        }}
      >
        Sign out
      </Sidebar.Item>
    </Sidebar.Collapse>
  );
}

const MemoizedAvatar = memo(
  // @ts-ignore
  function MemoizedAvatar({ img }) {
    return (
      <Avatar
        img={img}
        size="xs"
        rounded
        className="mr-1 h-auto"
        alt="Profile picture"
      />
    );
  },
);

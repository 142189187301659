import { useRollbar } from "@rollbar/react";
import React from "react";
import { toast } from "react-toastify";

const useManagedCallback = (callback, dependencies) => {
  const rollbar = useRollbar();

  return React.useCallback(async (...args) => {
    try {
      return await callback(...args);
    } catch (error) {
      rollbar.error(error);
      toast.error("Something went wrong. Please try again later.", {
        position: "bottom-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useManagedCallback;
